import { Button, Input, Select, SelectOptionType, Switch } from '@bp/ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InstantMeetings.module.scss';
import { CalendarEventCreateInput, useBpCreateCalendarEventMutation } from '../../../client/bp-graphql-client-defs';
import { connectByUuid } from '../../../utils/connectLib';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import dayjs from 'dayjs';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { showErrorToast } from '../../../utils/showErrorToast';
import { startAndJoinMeeting } from './startAndJoinMeeting';
import i18next from 'i18next';
import { v4 } from 'uuid';
import { backendApi } from '../../../utils/backendApi';
import { AvailableService } from '../../../hooks/useAvailableServicesForOrganization';
import { useGroupMembersCount } from '../../../hooks/useGroupMembersCount';
import { useDays } from '../../../hooks/useDays';

export const DEFAULT_MEETING_DURATION: number = 90;

export type InstantMeeting = {
  participantsCount?: number;
  duration: number;
  record: boolean;
  service: string;
};

export function InstantMeetings({
  handleClose,
  availableServices,
  groupUuid,
}: {
  handleClose: () => void;
  availableServices: AvailableService[];
  groupUuid: string;
}) {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const { today } = useDays();
  const context = useMemoizedCacheTag('EVENT');
  const [, bpCreateEvents] = useBpCreateCalendarEventMutation();

  const { groupMembers, organisationUuid } = useGroupMembersCount(groupUuid);

  async function handleSubmit(values: InstantMeeting, formikHelpers: FormikHelpers<InstantMeeting>) {
    let canSave = true;

    if (values.service.includes('bbb')) {
      const attendees = values.participantsCount ?? 0;
      const tempUuid = v4();
      const slots = await backendApi.testForSufficientBBBSlots({
        id: tempUuid,
        maxAttendees: attendees,
        start: dayjs().toISOString(),
        end: dayjs()
          .add(dayjs.duration(values.duration ? values.duration : DEFAULT_MEETING_DURATION, 'minutes'))
          .toISOString(),
        organization: pimAuthClaims.getOrganizationUuid(),
      });

      if (slots[0][tempUuid] <= 0) canSave = false;

      if (!canSave) {
        showErrorToast({
          message: i18next.t('meetings.tooManyParticipantsWithNumber', { number: attendees + slots[0][tempUuid] }),
          name: '',
          graphQLErrors: [],
        });
      }
    }

    if (canSave) {
      const input: CalendarEventCreateInput = {
        title: t('meetings.instantMeeting'),
        organization: connectByUuid(organisationUuid),
        holder: { Group: connectByUuid(groupUuid) },
        start: dayjs().toISOString(),
        duration: ((values.duration ?? DEFAULT_MEETING_DURATION) * 60).toString(),
        days: { connect: [{ where: { node: { uuid: today?.uuid } } }] },
        owner: connectByUuid(pimAuthClaims.getProfile().uuid ?? ''),
        maxAttendees: values.participantsCount ?? 1,
        recordVirtualEvent: values.record ?? false,
        categories: values.service ? ['virtual', values.service] : undefined, // 'virtual' MUSS hier mit bei, das backend prüft danach
      };

      const { error, data } = await bpCreateEvents({ input }, context);

      if (error) {
        showErrorToast(error);
      } else {
        formikHelpers.resetForm();
        handleClose();

        const joinSuccess =
          data?.createCalendarEvents.calendarEvents &&
          data?.createCalendarEvents.calendarEvents?.length > 0 &&
          (await startAndJoinMeeting(data.createCalendarEvents.calendarEvents[0], pimAuthClaims, groupUuid));

        if (!joinSuccess)
          showErrorToast({
            message: i18next.t('meetings.noVirtualLocation'),
            name: '',
            graphQLErrors: [],
          });
      }
    }
  }

  function getServiceOptions() {
    const services = [];
    if (availableServices.find((s) => s.service === 'BigBlueButton'))
      services.push({ value: 'bbb', label: 'BigBlueButton' });
    if (availableServices.find((s) => s.service === 'Zoom')) services.push({ value: 'zoom', label: 'Zoom' });
    return services;
  }

  const initialValues: InstantMeeting = {
    record: false,
    service: '',
    duration: DEFAULT_MEETING_DURATION,
    participantsCount: groupMembers + 10,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => (
        <Form>
          <div className={styles.wrapper}>
            <Select
              name='service'
              options={getServiceOptions()}
              placeholder='Service auswählen'
              required={true}
              label='Über welchen Service wollen Sie die Besprechung starten?'
              onChange={(opt) => formik.setFieldValue('service', (opt as SelectOptionType).value)}
              error={formik.errors.service}
              onBlur={formik.handleBlur}
            />
            {formik.values.service && (
              <>
                <hr style={{ width: '100%' }} />
                <div className={styles.flex}>
                  <Input
                    name='participantsCount'
                    placeholder={t('meetings.participantsCount.placeholder', { count: '100' })}
                    label={t('meetings.participantsCount.label')}
                    onChange={(ev) => formik.setFieldValue('participantsCount', +ev.target.value)}
                    onBlur={formik.handleBlur}
                    value={formik.values.participantsCount}
                    error={formik.errors.participantsCount}
                  />
                  <Input
                    name='duration'
                    placeholder='in Minuten'
                    label={t('meetings.expectedDuration')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.duration?.toString() as string}
                    error={formik.errors.duration}
                  />
                </div>

                {availableServices.find((s) => s.service === formik.values.service)?.allowRecording && (
                  <div className={`${styles.flex} ${styles['no-column']}`}>
                    <div>{t('meetings.record')}</div>
                    <Switch size='large' name='record' onChange={formik.handleChange} />
                  </div>
                )}
                <div>
                  <Button type='submit' hierarchy='primary' fullWidth={true}>
                    {t('meetings.start')}
                  </Button>
                  <Button type='submit' hierarchy='primary' fullWidth={true}>
                    {t('meetings.startAndJoin')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
