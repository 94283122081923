import { FC, useMemo } from 'react';
import {
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  GroupsIcon,
  Row,
  Table,
  TableColumns,
  Tooltip,
  useDefaultSorting,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useConfirm } from '../../hooks/useConfirm';
import { Dayjs } from 'dayjs';
import { getExternalService } from '../../utils/getExternalServiceHelper';
import { AdminRoles, ProfileRoles } from '@bp/pim-auth-constants';
import { BpLink } from '../BpLink/BpLink';
import { Profile } from '@bp/bp-graphql-types';

export type GroupsTableType = {
  uuid: string;
  organizationUuid: string;
  organizationName: string;
  name: string;
  meetingInProgress: boolean;
  managedBy: string;
  newMessage: boolean;
  isCommunity: boolean;
  schoolyearName?: string;
  admins: Partial<Omit<Profile, 'organization'>>[];
  editors: Partial<Omit<Profile, 'organization'>>[];
  viewers: Partial<Omit<Profile, 'organization'>>[];
  externalServices?: {
    nextcloud?: { lastSync?: Dayjs; enabled: boolean };
    threema?: { lastSync?: Dayjs; enabled: boolean };
    zoom?: { lastSync?: Dayjs; enabled: boolean };
  };
};

type GroupsTableProps = {
  data: GroupsTableType[];
  onNavigate: (uuid: string) => void;
  onAdd?: () => void;
  onEdit?: (uuid: string) => void;
  onEditProfiles?: (uuid: string) => void;
  onDelete?: (uuid: string) => void;
  onSettings?: (uuid: string) => void;
  communityDetails?: boolean;
};

export const GroupsTable: FC<GroupsTableProps> = ({
  data,
  onNavigate,
  onAdd,
  onEdit,
  onEditProfiles,
  onDelete,
  onSettings,
  communityDetails,
}) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organization = pimAuthClaims.getOrganization();
  const perms = usePermissionChecker();

  const { sorting, onSortingChange } = useDefaultSorting();

  const canAdd = onAdd && perms?.canCreateBpGroups();
  // full-edit is only available for Groups in my Organization
  const canEdit = (row: Row<GroupsTableType>) =>
    onEdit &&
    perms?.canEditBpGroup({ uuid: row.original.uuid, organization: { uuid: organization.uuid } }) &&
    row.original.organizationUuid === organization.uuid;

  // for Collaboration Groups only Members are editable
  const canEditProfiles = (row: Row<GroupsTableType>) =>
    perms?.canEditBpGroup({ uuid: row.original.uuid, organization: { uuid: organization.uuid } }) &&
    row.original.organizationUuid !== organization.uuid;

  const canDelete = onDelete && perms?.canDeleteBpGroups();

  const dropDownMenu = (row: Row<GroupsTableType>): DropdownMenuItem[] => {
    const menu: DropdownMenuItem[] = [];

    if (canEdit(row)) {
      menu.push({
        label: t('common.edit'),
        onClick: () => onEdit && onEdit(row.original.uuid),
      });
    }

    if (canEditProfiles(row)) {
      menu.push({
        label: t('common.editProfiles'),
        onClick: () => onEditProfiles && onEditProfiles(row.original.uuid),
      });
    }

    if (canEdit(row)) {
      menu.push({
        label: t('settings.title'),
        onClick: () => onSettings && onSettings(row.original.uuid),
      });
    }

    if (canDelete) {
      menu.push(
        { type: 'ruler' },
        {
          label: t('delete.delete'),
          color: 'error',
          onClick: async () => {
            const res = await confirmDelete();
            if (res) {
              onDelete(row.original.uuid);
            }
          },
        },
      );
    }

    return menu;
  };

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('groups.titleSingular'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  const columns: TableColumns<GroupsTableType>[] = useMemo((): TableColumns<GroupsTableType>[] => {
    const studentColumns = [
      {
        size: 400,
        canExpand: true,
        header: t('common.designation'),
        id: 'name',
        accessorKey: 'name',
        cell: ({ row }: { row: Row<GroupsTableType> }) => {
          return (
            <BpLink
              value={row.original.name}
              onNavigate={() => onNavigate(row.original.uuid)}
              hasNews={row.original.newMessage}
              hasNewsHint={t('groups.newMessageHint')}
              inProgress={row.original.meetingInProgress}
              isEditorOfCourse={
                perms?.isGroupEditor({ uuid: row.original.uuid, organization: { uuid: organization.uuid } }) ?? false
              }
            />
          );
        },
      },
    ];
    const teacherColumns: TableColumns<GroupsTableType>[] = [
      ...((communityDetails ?? false)
        ? [
            {
              header: t('institution.title'),
              id: 'organizationName',
              accessorKey: 'organizationName',
              size: 400,
              cell: ({ row }: { row: Row<GroupsTableType> }) => (
                <Tooltip triggerStyle={{ display: 'inline' }} content={row.original.organizationName ?? ''}>
                  {row.original.organizationName ?? ''}
                </Tooltip>
              ),
            },
          ]
        : [
            {
              header: t('common.community'),
              type: 'boolean' as TableColumns<GroupsTableType>['type'],
              id: 'isCommunity',
              accessorKey: 'isCommunity',
              size: 100,
            },
          ]),
      {
        size: 130,
        header: t('schoolyears.titleSingular'),
        id: 'schoolyearName',
        accessorKey: 'schoolyearName',
        accessorFn: (row) => {
          return row.schoolyearName ?? t('group.schoolyearSpanningShort');
        },
      },
      {
        header: t('common.managedBy'),
        id: 'managedBy',
        accessorKey: 'managedBy',
        size: 130,
      },
      {
        header: t('common.nextcloud'),
        id: 'nextcloud',
        accessorKey: 'nextcloud',
        cell: ({ row }: { row: Row<GroupsTableType> }) => {
          const service = getExternalService(
            row.original.externalServices?.nextcloud?.enabled ?? false,
            row.original.externalServices?.nextcloud?.lastSync,
          );
          return <div style={{ color: service.color ?? undefined }}>{service?.text?.toString() ?? ''}</div>;
        },
        size: 150,
      },
      {
        header: t('common.threema'),
        id: 'threema',
        accessorKey: 'threema',
        cell: ({ row }: { row: Row<GroupsTableType> }) => {
          const service = getExternalService(
            row.original.externalServices?.threema?.enabled ?? false,
            row.original.externalServices?.threema?.lastSync,
          );
          return <div style={{ color: service.color ?? undefined }}>{service?.text?.toString() ?? ''}</div>;
        },
        size: 150,
      },
    ];

    return perms?.hasOrganizationRole(ProfileRoles.Teacher, { uuid: organization.uuid }) ||
      perms?.hasOrganizationRole(AdminRoles.GroupAdmin, { uuid: organization.uuid }) ||
      perms?.isOrganizationAdmin({ uuid: organization.uuid }) ||
      perms?.isOmniAdmin()
      ? [...studentColumns, ...teacherColumns]
      : studentColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization.uuid, perms]);

  return (
    <>
      <Table<GroupsTableType>
        data={data}
        columns={columns}
        canScroll
        showSort
        breakpoint='580px'
        sorting={sorting}
        onSortingChange={onSortingChange}
        showActionBar
        actionBarSettings={{
          showAddButton: canAdd,
          addButtonText: t('groups.addGroup'),
        }}
        isOnWhite={false}
        showBorderRadius
        showShadow
        onAddClick={() => onAdd && onAdd()}
        emptyStateSettings={{ icon: <GroupsIcon className='svg-icon primary-light' />, padding: 'l' }}
        lastCol={(row: Row<GroupsTableType>) => {
          const menu = dropDownMenu(row);
          if (menu.length === 0) return;
          return (
            <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
              <DropdownMenu data={menu} />
            </Dropdown>
          );
        }}
      />

      <ConfirmationDialog />
    </>
  );
};
