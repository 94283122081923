import { Suspense, useContext, useState } from 'react';
import { findClosestEventToNow, getDaytimeGreeting, isBefore } from '../../utils/dateCalculations';
import styles from './Home.module.scss';
import { useNavigate } from 'react-router-dom';
import { AddIcon, Grid, GridColumn, GridRow, LazyLoader, Modal, Tab } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { BpCalendar } from '../../components/BpCalendar/BpCalendar';
import { BpCard } from '../../components/BpCard/BpCard';
import { EventsList } from '../../components/EventsList/EventsList';
import { useDashboardCalendarEvents, useGroupOrCoursesOnlineEvents } from '../../hooks/useCalendarEvents';
import { BpPage } from '../../components/BpPage/BpPage';
import { AnnouncementsList } from '../../components/AnnouncementsList/AnnouncementsList';
import { LinkList } from '../../components/LinkList/LinkList';
import { ListsBar } from '../../components/ListsBar/ListsBar';
import {
  useBpAssignmentsQuery,
  useProfileCoursesQuery,
  useProfileGroupsQuery,
} from '../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import classNames from 'classnames';
import { CoursesPreviewTable, CoursesPreviewTableType } from '../../components/CoursesPreviewTable/CoursesPreviewTable';
import { GroupsPreviewTable, GroupsPreviewTableType } from '../../components/GroupsPreviewTable/GroupsPreviewTable';
import { useGetMatrixRoomByName } from '../../hooks/matrix/useGetMatrixRoomByName';
import { NotificationReasons, RoomNames } from '../../utils/matrixClient';
import { useGetAnnouncements } from '../../hooks/matrix/useGetAnnouncements';
import { AnnouncementForm, AnnouncementFormType } from '../../components/AnnouncementForm/AnnouncementForm';
import { BpHeader } from '../../components/BpHeader/BpHeader';
import { OpenAssignmentsOverview } from '../../components/OpenAssignmentsOverview/OpenAssignmentsOverview';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { useGetMatrixRooms } from '../../hooks/matrix/useGetMatrixRooms';
import { useRefreshOnEvent } from 'hooks/matrix/useRefreshOnEvent';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { AppointmentForm } from 'components/AppointmentForm/AppointmentForm';
import { backendApi } from '../../utils/backendApi';

export const Home = () => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const navigate = useNavigate();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const courseContext = useMemoizedCacheTag('COURSE_LIST');
  const groupContext = useMemoizedCacheTag('GROUP_LIST');
  const assignmentContext = useMemoizedCacheTag('ASSIGNMENT');

  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);

  const { currentSchoolYear } = useContext(OrganizationConfigContext);
  const { dashboardEvents } = useDashboardCalendarEvents(pimAuthClaims.getProfile().uuid);

  useRefreshOnEvent();

  const closestEvent = findClosestEventToNow(dashboardEvents);
  const [scrollUuid, setScrollUuid] = useState<string | null>(closestEvent ? closestEvent.uuid : null);
  const [randomRedrawString, setRandomRedrawString] = useState<string>('');

  const firstName = pimAuthClaims.getProfile()?.firstName ?? '';

  const { getRoom } = useGetMatrixRoomByName();
  const room = getRoom(organizationUuid, RoomNames.Organization);
  const announcements = useGetAnnouncements(room);

  const [{ data: courseData }] = useProfileCoursesQuery({
    variables: {
      organizations: pimAuthClaims.getAllowedOrganizationUuids(),
      profile: pimAuthClaims.getProfile().uuid,
    },
    context: courseContext,
  });

  const [{ data: groupsData }] = useProfileGroupsQuery({
    variables: {
      organizations: pimAuthClaims.getAllowedOrganizationUuids(),
      profile: pimAuthClaims.getProfile().uuid,
    },
    context: groupContext,
  });

  const [{ data: assignmentsQueryResult }] = useBpAssignmentsQuery({
    context: assignmentContext,
  });

  const handleAnnouncementFormSubmit = (values?: AnnouncementFormType) => {
    setAnnouncementModalOpen(false);
    values &&
      void backendApi.notify({
        type: NotificationReasons.Announcement,
        content: values.message,
        title: values.title,
        organizationUuid: organizationUuid,
      });
  };
  const myAssignments = assignmentsQueryResult?.assignments.filter((a) =>
    a.submissions.some((s) => s.owner.uuid === pimAuthClaims.getProfile().uuid),
  );

  const rooms = useGetMatrixRooms();

  const onlineEvents = useGroupOrCoursesOnlineEvents([
    ...(courseData?.groups ?? []).map((c) => c.uuid),
    ...(groupsData?.groups ?? []).map((c) => c.uuid),
  ]);

  const coursesPreviewData: CoursesPreviewTableType[] =
    courseData?.groups
      .filter((c) => c.schoolYear?.uuid === currentSchoolYear.uuid || c.isCommunity)
      .map((course) => {
        const notificationRoom = rooms?.find((room) => room.name.includes(course.uuid));
        let newMessages = false;

        if (notificationRoom) {
          newMessages = notificationRoom.getRoomUnreadNotificationCount() > 0;
        }

        if (!newMessages) {
          assignmentsQueryResult?.assignments
            .filter((assignments) => {
              return assignments.holder.group.uuid === course.uuid;
            })
            .forEach((assigment) => {
              assigment.submissions.forEach((submission) => {
                const room = rooms?.find((room) => {
                  return room.name.includes(submission.uuid);
                });
                if (room && !newMessages) {
                  newMessages = room.getRoomUnreadNotificationCount() > 0;
                }
              });
            });
        }

        return {
          uuid: course.uuid,
          organization: course.organization,
          name: course.name,
          meetingInProgress:
            onlineEvents.find((v) => v.originUuid === course.uuid)?.virtualLocations.find((v) => v.running)?.running ??
            false,
          count: 0,
          newMaterial: newMessages,
        };
      }) ?? [];

  const groupsPreviewData: GroupsPreviewTableType[] =
    groupsData?.groups.map((group) => {
      const notificationRoom = rooms?.find((room) => room.name.includes(group.uuid));
      let newMessages = false;

      if (notificationRoom) {
        newMessages = notificationRoom.getRoomUnreadNotificationCount() > 0;
      }

      return {
        uuid: group.uuid,
        organization: group.organization,
        name: group.name,
        meetingInProgress:
          onlineEvents.find((v) => v.originUuid === group.uuid)?.virtualLocations.find((v) => v.running)?.running ??
          false,
        count: 0,
        newMaterial: newMessages,
      };
    }) ?? [];

  const teacherTabs = [
    {
      title: t('common.calendar'),
      value: 'calendar',
      content: (
        <div className={classNames(styles.tab, styles['calendar-tab'])}>
          <BpCard className={styles.calendar} noPadding showBorder hideBackground>
            <BpCalendar
              events={dashboardEvents}
              showAddButton={perms?.isOrganizationAdmin(pimAuthClaims.getOrganization())}
              onAdd={() => setAppointmentModalOpen(true)}
              isOnWhite
              eventClick={(uuid) => {
                setRandomRedrawString(Date.now().toString());
                setScrollUuid(uuid);
              }}
            />
          </BpCard>
          <EventsList
            className={styles.events}
            isOnWhite
            showCompactEvents
            events={dashboardEvents}
            scrollToUuid={scrollUuid}
            randomRedrawString={randomRedrawString}
          />
        </div>
      ),
    },
    {
      content: (
        <div className={classNames(styles.tab, styles['timetable-tab'])}>
          <img src='./timetable-mockup.png' alt={'Beispiel Stundenplan'} />
        </div>
      ),
      title: t('common.timetable'),
      value: 'timetable',
    },
  ];

  const activeAssignments = myAssignments?.filter((a) => isBefore(new Date(), a.dueDate?.date)) ?? [];

  const studentTabs = [
    {
      content: (
        <div className={classNames(styles.tab, styles['gantt-tab'])}>
          <OpenAssignmentsOverview
            assignments={activeAssignments}
            onNavigate={async (uuid) => {
              const courseUuid = myAssignments?.find((a) => a.uuid === uuid)?.holder.group.uuid ?? '0';
              navigate(`/courses/${courseUuid}/assignments/${uuid}`);
            }}
          />
        </div>
      ),
      title: t('assignments.title'),
      value: 'assignments',
    },
    {
      content: (
        <div className={classNames(styles.tab, styles['timetable-tab'])}>
          <img src='./timetable-mockup.png' alt={'Beispiel Stundenplan'} />
        </div>
      ),
      title: t('common.timetable'),
      value: 'timetable',
    },
  ];

  const classes = classNames(styles.home, {
    [styles['no-bar']]: !perms?.canCreateCourse(),
  });

  return (
    <BpPage noHeadlineMargin className={classes} title='' isForbidden={false}>
      <BpHeader breakLine headline={getDaytimeGreeting(firstName)} size='l' noMargin={perms?.canCreateCourse()} />
      {perms?.canCreateCourse() && <ListsBar />}
      <Grid>
        <GridRow mobileGap='var(--grid-column-gap)'>
          <GridColumn width={4}>
            <BpCard
              className='mb-7'
              fixedHeight='432px'
              noPadding
              canScroll
              header={{ headline: t('courses.title'), subHeadline: coursesPreviewData.length ?? 0 }}
            >
              <CoursesPreviewTable data={coursesPreviewData} onNavigate={(uuid) => navigate('/courses/' + uuid)} />
            </BpCard>
            <BpCard
              fixedHeight='432px'
              noPadding
              canScroll
              header={{ headline: t('groups.title'), subHeadline: groupsData?.groups.length ?? 0 }}
            >
              <GroupsPreviewTable data={groupsPreviewData} onNavigate={(uuid) => navigate('/groups/' + uuid)} />
            </BpCard>
          </GridColumn>
          <GridColumn width={8}>
            <GridRow spacingTop='none'>
              <BpCard className={styles['calendar-card']} noPadding minHeight='584px'>
                <Tab
                  className={styles['tab-header']}
                  tabs={
                    perms?.hasOrganizationRole(ProfileRoles.Student, pimAuthClaims.getOrganization())
                      ? studentTabs
                      : teacherTabs
                  }
                />
              </BpCard>
            </GridRow>
            <GridRow mobileGap='var(--grid-column-gap)'>
              <GridColumn width={6}>
                <BpCard
                  noPadding
                  fixedHeight='280px'
                  canScroll
                  header={{
                    headline: t('announcements.title'),
                    actions: perms?.canCreateOrganizationAnnouncment({ uuid: organizationUuid })
                      ? [
                          {
                            text: t('common.add'),
                            icon: <AddIcon />,
                            callback: () => {
                              setAnnouncementModalOpen(true);
                            },
                          },
                        ]
                      : [],
                  }}
                >
                  <AnnouncementsList
                    emptyStateSettings={{ hideIcon: true, forcedHeight: '' }}
                    announcements={announcements}
                    hasPermissionToDelete={
                      perms?.canCreateOrganizationAnnouncment({
                        uuid: organizationUuid,
                      }) ?? false
                    }
                  />
                </BpCard>
              </GridColumn>
              <GridColumn width={6}>
                <BpCard fixedHeight='280px' header={{ headline: t('links.title') }}>
                  <LinkList />
                </BpCard>
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
      </Grid>

      <Modal
        isOpen={appointmentModalOpen}
        onRequestClose={() => setAppointmentModalOpen(false)}
        isFormModal
        hideHeader
        hideFooter
      >
        <Suspense fallback={<LazyLoader embedded transparent forceHeight='40vh' />}>
          <AppointmentForm
            organizationUuid={organizationUuid}
            holderUuid={organizationUuid}
            context='Organization'
            isModal
            onClose={() => setAppointmentModalOpen(false)}
          />
        </Suspense>
      </Modal>

      <Modal
        isOpen={announcementModalOpen}
        onRequestClose={() => setAnnouncementModalOpen(false)}
        isFormModal
        hideFooter
        title={t('announcements.new')}
        width='s'
      >
        <Suspense fallback={<LazyLoader embedded transparent />}>
          <AnnouncementForm onSubmit={handleAnnouncementFormSubmit} />
        </Suspense>
      </Modal>
    </BpPage>
  );
};
