import * as yup from 'yup';

export const courseSchema = yup.object().shape({
  title: yup.string().required('Titel ist erforderlich'),
  teachingUnitUuid: yup.string().min(2).required('Unterrichtseinheit ist erforderlich'),
});

export const groupSchema = yup.object().shape({
  title: yup.string().required('Titel ist erforderlich'),
});
