import {
  AddIcon,
  CheckIcon,
  EmptyState,
  Grid,
  GridColumn,
  GridRow,
  Modal,
  SendIcon,
  SpinnerIcon,
} from '@bp/ui-components';
import { BpCalendar } from '../../../components/BpCalendar/BpCalendar';
import { MeetingsLinks } from '../../../components/MeetingsLinks/MeetingsLinks';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { FC, Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { WorkMaterialsList } from '../../../components/WorkMaterialsList/WorkMaterialsList';
import { BpSubpage } from 'components/BpSubpage/BpSubpage';
import { BpCard } from '../../../components/BpCard/BpCard';
import { useBpTeachingUnitsQuery, useGroupAsGroupsQuery, UserStatus } from '../../../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { AnnouncementsList } from '../../../components/AnnouncementsList/AnnouncementsList';
import { useGroupCalendarEvents, useRunningPlannedMeetings } from '../../../hooks/useCalendarEvents';
import { useGetMatrixRoomByName } from '../../../hooks/matrix/useGetMatrixRoomByName';
import { NotificationReasons, RoomNames } from '../../../utils/matrixClient';
import { AnnouncementForm, AnnouncementFormType } from '../../../components/AnnouncementForm/AnnouncementForm';
import { useGetAnnouncements } from '../../../hooks/matrix/useGetAnnouncements';
import { useMarkAsRead } from '../../../hooks/matrix/useMarkAsRead';
import { NotificationCountType } from 'matrix-js-sdk';
import { SendMatrixMessageForm } from '../../../components/SendMatrixMessageForm/SendMatrixMessageForm';
import { uniqueByUuid } from '../../../utils/uniquByUuid';
import { GroupType } from '@bp/bp-graphql-types';
import { hasBlockingEvents } from '../../../utils/hasBlockingEvents';
import { useAvailableServicesForOrganization } from '../../../hooks/useAvailableServicesForOrganization';
import { backendApi } from '../../../utils/backendApi';

export const GroupOverviewSubpage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  const { groupUuid } = useParams<{ groupUuid: string }>();
  const [announcmentModal, setAnnouncmentModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const { markAsRead } = useMarkAsRead();
  const { getRoom } = useGetMatrixRoomByName();

  const [{ data: groupsData }] = useGroupAsGroupsQuery({
    context: useMemoizedCacheTag('GROUP'),
    variables: { where: { uuid: groupUuid ?? '', type: GroupType.Group } },
  });

  const currentGroup = useMemo(() => {
    return groupsData?.groups[0];
  }, [groupsData]);

  const room = getRoom(`${currentGroup?.organization.uuid}_${groupUuid}`, RoomNames.Group);
  const announcements = useGetAnnouncements(room);
  const { runningMeetings } = useRunningPlannedMeetings(pimAuthClaims.getProfile().uuid);

  const availableServices = useAvailableServicesForOrganization(currentGroup?.organization.uuid ?? '');

  const _hasUnread = room ? room.getRoomUnreadNotificationCount(NotificationCountType.Total) > 0 : false;
  const [hasUnread, setHasUnread] = useState<boolean>(false);

  useEffect(() => {
    setHasUnread(_hasUnread);
  }, [_hasUnread]);

  const events = useGroupCalendarEvents(groupUuid ?? '');

  const context = useMemoizedCacheTag('TEACHING_UNIT');

  const [{ data }] = useBpTeachingUnitsQuery({
    variables: {
      teachingUnitsWhere: {
        group: {
          uuid: groupUuid,
        },
      },
    },
    context,
  });
  const workMaterial = data?.teachingUnits[0]?.workMaterials;

  const groupContext = useMemoizedCacheTag('GROUP');
  const [{ data: groupData }] = useGroupAsGroupsQuery({
    context: groupContext,
    variables: { where: { uuid: groupUuid ?? '', type: GroupType.Group } },
  });

  const viewers = groupData?.groups[0].viewers ?? [];
  const receiverProfiles = uniqueByUuid(
    viewers.filter((profile) => {
      return (
        profile.uuid !== pimAuthClaims.getProfile().uuid &&
        (profile.userStatus === UserStatus.Full || profile.userStatus === UserStatus.ImpersonateOnly)
      );
    }),
  );

  if (!groupUuid) {
    return (
      <EmptyState
        fitParent
        forcedHeight='300px'
        size='small'
        subtitle={t('groups.noGroups')}
        iconColor='var(--color-primary-light)'
        icon={<SendIcon />}
      />
    );
  }
  const groupSubject = { uuid: groupUuid ?? '', organization: { uuid: currentGroup?.organization.uuid ?? '' } };

  const handleAnnouncementFormSubmit = (values?: AnnouncementFormType) => {
    setAnnouncmentModal(false);
    values &&
      void backendApi.notify({
        type: NotificationReasons.Announcement,
        content: values.message,
        title: values.title,
        groupUuid: groupUuid,
      });
  };

  return (
    <BpSubpage>
      <Grid>
        <GridRow mobileGap='var(--grid-column-gap)'>
          <GridColumn width={8}>
            <GridRow mobileGap='var(--grid-column-gap)' direction='column' spacingTop='none'>
              <BpCard
                canScroll
                fixedHeight='374px'
                noPadding
                header={{
                  headline: t('workmaterials.title'),
                  actions: perms?.canCreateBpGroupMaterial({
                    uuid: groupUuid,
                    organization: { uuid: currentGroup?.organization.uuid ?? '' },
                  })
                    ? [
                        {
                          text: t('workmaterials.addMaterial'),
                          icon: <AddIcon />,
                          callback: () => navigate(`/groups/${groupUuid}/workmaterials/add`),
                        },
                      ]
                    : undefined,
                }}
              >
                <WorkMaterialsList
                  workMaterial={workMaterial ?? []}
                  onNavigate={(uuid) => navigate(`/groups/${groupUuid}/workmaterials/${uuid}`)}
                  isGroupEditor={
                    !!perms?.isGroupEditor({
                      uuid: groupUuid,
                      organization: { uuid: currentGroup?.organization.uuid ?? '' },
                    })
                  }
                />
              </BpCard>
            </GridRow>
            <GridRow>
              <BpCard
                canScroll
                fixedHeight='374px'
                noPadding
                header={{
                  headline: t('announcements.title'),
                  actions: perms?.canCreateGroupAnnouncement(groupSubject)
                    ? hasUnread
                      ? [
                          {
                            text: t('common.mark'),
                            hierarchy: 'ghost',
                            icon: <CheckIcon />,
                            callback: () => {
                              setHasUnread(false);
                              markAsRead(room);
                            },
                          },
                          {
                            text: t('announcements.new'),
                            icon: <AddIcon />,
                            callback: () => {
                              setAnnouncmentModal(true);
                            },
                          },
                        ]
                      : [
                          {
                            text: t('announcements.new'),
                            icon: <AddIcon />,
                            callback: () => {
                              setAnnouncmentModal(true);
                            },
                          },
                        ]
                    : hasUnread
                      ? [
                          {
                            text: t('common.mark'),
                            hierarchy: 'ghost',
                            icon: <CheckIcon />,
                            callback: () => {
                              setHasUnread(false);
                              markAsRead(room);
                            },
                          },
                        ]
                      : undefined,
                }}
              >
                <AnnouncementsList
                  announcements={announcements}
                  hasPermissionToDelete={perms?.canCreateGroupAnnouncement(groupSubject) ?? false}
                />
              </BpCard>
            </GridRow>
          </GridColumn>
          <GridColumn width={4}>
            <GridRow spacingTop='none'>
              <BpCard className='pb-6 pr-6 pl-6' noPadding showBorder hideBackground>
                <BpCalendar
                  events={events}
                  showAddButton={!!groupUuid}
                  onAdd={() => navigate(`/groups/${groupUuid}/appointments/create`)}
                  eventClick={() => navigate(`/groups/${groupUuid}/appointments`)}
                />
              </BpCard>
            </GridRow>
            <GridRow>
              <BpCard hideBackground noPadding showOverflow>
                <MeetingsLinks
                  groupUuid={groupUuid}
                  availableServices={availableServices}
                  hasBlockingEvents={hasBlockingEvents(events)}
                  runningEvent={runningMeetings.find((m) => m.originUuid === groupUuid)}
                  onLessonClick={() => navigate('/meetings/planned')}
                  onMessageClick={() => setMessageModal(true)}
                />
              </BpCard>
            </GridRow>
          </GridColumn>
        </GridRow>
      </Grid>

      {room && (
        <Modal
          isOpen={announcmentModal}
          onRequestClose={() => setAnnouncmentModal(false)}
          isFormModal
          hideFooter
          title={t('announcements.new')}
          width='s'
        >
          <Suspense fallback={<SpinnerIcon className={'svg-icon large spinning'} />}>
            <AnnouncementForm onSubmit={handleAnnouncementFormSubmit} />
          </Suspense>
        </Modal>
      )}

      {receiverProfiles && (
        <Modal
          isOpen={messageModal}
          onRequestClose={() => setMessageModal(false)}
          isFormModal
          hideFooter
          width={'s'}
          title={t('common.sendToAllGroupViewers')}
        >
          <Suspense fallback={<SpinnerIcon className={'svg-icon large spinning'} />}>
            <SendMatrixMessageForm onClose={() => setMessageModal(false)} receiverProfiles={receiverProfiles} />
          </Suspense>
        </Modal>
      )}
    </BpSubpage>
  );
};
