import {
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  MeetingDefaultIcon,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { GroupType } from '@bp/bp-graphql-types';
import { copyEventLinkToClipboard, endEvent, startAndJoinEvent, startEvent } from 'utils/eventHelper';
import { useState } from 'react';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useConfirm } from 'hooks/useConfirm';

export type RunningMeetingType = {
  eventUuid: string;
  originUuid: string;
  locationUuid: string;
  running: boolean;
  name: string;
  origin: 'Profile' | 'Organization' | GroupType;
  originName: string;
  start: string;
  attendees: number;
  withVideo: number;
  maxAttendees: number;
  uri: string;
  token: string;
};

type RunningMeetingsTableProps = {
  meetings: RunningMeetingType[];
  type: 'bbb' | 'zoom';
  onRefetch: () => void;
};

export const RunningMeetingsTable = ({ meetings, type, onRefetch }: RunningMeetingsTableProps) => {
  const { t } = useTranslation();
  const profile = useAuthClaims().pimAuthClaims.getProfile();

  const [loading, setLoading] = useState<boolean>(false);

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('meetings.titleShort'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  const bbbColumns: TableColumns<RunningMeetingType>[] = [
    {
      header: t('meetings.origin'),
      id: 'origin',
      accessorKey: 'origin',
      cell: ({ row }) => {
        switch (row.original.origin) {
          case 'Profile':
            return t('persons.titleSingular');
          case GroupType.Course:
            return t('courses.titleSingular');
          case GroupType.Group:
            return t('groups.titleSingular');
          default:
            break;
        }
      },
      size: 200,
    },
    { header: t('common.designation'), id: 'originName', accessorKey: 'originName', size: 350 },
    {
      header: t('common.started'),
      id: 'start',
      accessorKey: 'start',
      size: 130,
    },
    {
      header: t('meetings.table.attendees'),
      id: 'attendees',
      accessorKey: 'attendees',
      size: 80,
    },
    {
      header: t('meetings.table.withVideo'),
      id: 'withVideo',
      accessorKey: 'withVideo',
      size: 80,
    },
    {
      header: t('meetings.table.maxAttendees'),
      id: 'maxAttendees',
      accessorKey: 'maxAttendees',
      size: 80,
    },
    { header: t('meetings.table.running'), id: 'running', accessorKey: 'running', type: 'active' },
  ];

  // TODO: adjust when zoom-meetings are more defined
  const zoomColumns: TableColumns<RunningMeetingType>[] = [
    {
      header: t('meetings.table.licenseFrom'),
      id: 'licenseFrom',
      accessorKey: 'licenseFrom',
    },
    {
      header: t('meetings.moderator'),
      id: 'moderator',
      accessorKey: 'moderator',
    },
    {
      header: t('common.started'),
      id: 'start',
      accessorKey: 'start',
    },
    {
      header: t('common.status'),
      id: 'status',
      accessorKey: 'status',
    },
  ];

  const menu = ({ uri, token, running }: RunningMeetingType): DropdownMenuItem[] => {
    return [
      {
        label: t('meetings.start'),
        onClick: async () => {
          await startEvent(token);
          onRefetch();
        },
        disabled: running,
      },
      { label: t('meetings.copyLink'), onClick: () => copyEventLinkToClipboard(uri) },
      {
        label: t('meetings.joinAsModerator'),
        onClick: async () => {
          await onJoin(token, true);
          onRefetch();
        },
        disabled: !running,
      },
      {
        label: t('meetings.joinAsMember'),
        onClick: async () => {
          await onJoin(token, false);
          onRefetch();
        },
        disabled: !running,
      },
      { type: 'ruler' },
      {
        label: t('meetings.endImmediately'),
        color: 'error',
        onClick: async () => {
          const res = await confirmDelete();
          if (res) {
            await onEnd(token);
            onRefetch();
          }
        },
        disabled: !running,
      },
    ];
  };

  async function onJoin(token: string, asModerator: boolean) {
    setLoading(true);
    await startAndJoinEvent(token, profile, asModerator);
    setLoading(false);
  }

  async function onEnd(token: string) {
    setLoading(true);
    await endEvent(token);
    setLoading(false);
  }

  return (
    <>
      <Table<RunningMeetingType>
        hideHeader={meetings.length === 0}
        columns={type === 'bbb' ? bbbColumns : zoomColumns}
        data={meetings}
        emptyStateSettings={{
          title: t('meetings.noRunningMeetings'),
          icon: <MeetingDefaultIcon />,
          subtitle: '',
        }}
        canScroll={meetings.length !== 0}
        customPadding='var(--spacing-6)'
        lastCol={(row: Row<RunningMeetingType>) => {
          return (
            <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} isLoading={loading} />}>
              <DropdownMenu data={menu(row.original)} />
            </Dropdown>
          );
        }}
      />

      <ConfirmationDialog />
    </>
  );
};
