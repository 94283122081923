import { Form, Formik } from 'formik';
import { type FC } from 'react';
import { CombinedError } from 'urql';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import {
  useBpCreateWorkMaterialsMutation,
  useBpUpdateWorkMaterialsMutation,
  useBpWorkMaterialsQuery,
} from '../../client/bp-graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { Button, InplaceEdit, LazyLoader } from '@bp/ui-components';
import { BpCard } from '../BpCard/BpCard';
import { FileUpload } from '../FileUpload/FileUpload';
import styles from './WorkMaterialsForm.module.scss';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { type FileEntryTableType, FileTable } from '../FileTable/FileTable';
import { groupSchema } from './validation/schema';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { BpTipTap } from '../BpTipTap/BpTipTap';
import { connectByUuid, connectDay, fileEntriesConnect } from '../../utils/connectLib';
import { useWindowDimensions } from 'utils/dimensions';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

type GroupWorkMaterialsFormProps = {
  canArchive: boolean;
  onClose: (uuid?: string, title?: string) => void;
  workMaterialUuid?: string;
  teachingUnitUuid: string;
};

type WorkMaterialValuesType = {
  title: string;
  description: string;
  visibleFrom: string;
  visibleTo: string;
  publicFrom: string;
  visibleAfterVisibleTo: boolean;
  fileEntries: FileEntryTableType[];
  showPublicationDate: boolean;
  teachingUnitUuid: string;
};

export const GroupWorkMaterialsForm: FC<GroupWorkMaterialsFormProps> = ({
  canArchive,
  onClose,
  workMaterialUuid,
  teachingUnitUuid,
}) => {
  const { t } = useTranslation();
  const { groupUuid } = useParams();
  const context = useMemoizedCacheTag('WORKMATERIAL');
  const perms = usePermissionChecker();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();
  const profileUuid = useAuthClaims().pimAuthClaims.getProfile().uuid;

  const { isPhone } = useWindowDimensions();

  const [, bpCreateWorkMaterial] = useBpCreateWorkMaterialsMutation();
  const [, bpUpdateWorkMaterial] = useBpUpdateWorkMaterialsMutation();

  const [workmaterial] = useBpWorkMaterialsQuery({
    context,
    variables: {
      where: {
        uuid: workMaterialUuid,
      },
    },
  });

  const workMaterialToEdit = workmaterial.data?.workMaterials.find((mat) => mat.uuid === workMaterialUuid);

  const handleSubmit = async (values: WorkMaterialValuesType) => {
    let err: CombinedError | undefined;
    const { description, title, visibleTo, visibleFrom, publicFrom, visibleAfterVisibleTo } = values;

    const publicFromDate = dayjs(publicFrom).toISOString();
    const visibleFromDate = dayjs(visibleFrom).toISOString();
    const visibleToDate = dayjs(visibleTo).toISOString();

    if (workMaterialToEdit) {
      const { error } = await bpUpdateWorkMaterial(
        {
          where: { uuid: workMaterialUuid },
          update: {
            material: {
              delete: {},
              create: {
                node: {
                  text: description,
                  textMediaType: 'text/plain',
                  fileEntries: fileEntriesConnect(values.fileEntries),
                },
              },
            },
            publicFrom: {
              disconnect: {},
              ...connectDay(publicFromDate),
            },
            title: title,
            visibleAfterVisibleTo: visibleAfterVisibleTo,
            visibleFrom: {
              disconnect: {},
              ...connectDay(visibleFromDate),
            },
            visibleTo: {
              disconnect: {},
              ...connectDay(visibleToDate),
            },
            holder: {
              TeachingUnit: {
                disconnect: {},
                ...connectByUuid(teachingUnitUuid),
              },
            },
          },
        },
        context,
      );

      err = error;
    } else {
      if (
        perms?.canCreateWorkmaterial({
          uuid: groupUuid ?? '',
          organization: { uuid: organizationUuid },
        })
      ) {
        const { error } = await bpCreateWorkMaterial(
          {
            input: {
              title: title,
              visibleFrom: connectDay(visibleFromDate),
              visibleTo: connectDay(visibleToDate),
              visibleAfterVisibleTo: visibleAfterVisibleTo,
              publicFrom: connectDay(visibleFromDate),
              owner: connectByUuid(profileUuid),
              material: {
                create: {
                  node: {
                    text: description,
                    textMediaType: 'text/plain',
                    fileEntries: fileEntriesConnect(values.fileEntries),
                  },
                },
              },
              holder: {
                TeachingUnit: {
                  ...connectByUuid(teachingUnitUuid),
                },
              },
            },
          },
          context,
        );

        err = error;
      }
    }

    if (err) showErrorToast(err);
    if (!err) showSuccessToast(t('common.saved'));
    onClose(workMaterialUuid, values.title);
  };

  const dummyDate = dayjs().toISOString();
  const initialValues: WorkMaterialValuesType = {
    title: workMaterialToEdit?.title ?? '',
    description: workMaterialToEdit?.material.text ?? '',
    visibleFrom: workMaterialToEdit?.visibleFrom?.date ?? dummyDate,
    visibleTo: workMaterialToEdit?.visibleTo?.date ?? dummyDate,
    publicFrom: workMaterialToEdit?.publicFrom?.date ?? dummyDate,
    visibleAfterVisibleTo: workMaterialToEdit?.visibleAfterVisibleTo ?? false,
    showPublicationDate: !!workMaterialToEdit?.publicFrom?.date,
    fileEntries: workMaterialToEdit?.material.fileEntries ?? [],
    teachingUnitUuid: teachingUnitUuid,
  };

  return (
    <div className={styles['group-workmaterials-form']}>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={groupSchema}>
        {({ resetForm, setFieldValue, setFieldTouched, values, isSubmitting, errors }) => {
          return isSubmitting ? (
            <LazyLoader transparent forceHeight='50vh' />
          ) : (
            <Form className='bp__form'>
              <div className={'bp__form-header'}>
                <InplaceEdit
                  className={styles.title}
                  name='title'
                  fontSize={isPhone ? 'xl' : 'xxxl'}
                  onBlur={async (value) => {
                    await setFieldValue('title', value);
                    await setFieldTouched('title', true);
                  }}
                  onChange={async (value) => {
                    await setFieldValue('title', value);
                    await setFieldTouched('title', true);
                  }}
                  value={values.title}
                  placeholder={t('common.addTitle') as string}
                  error={errors.title}
                />
                <div className={'bp__form-buttons'}>
                  <Button hierarchy='tertiary' disabled={!canArchive}>
                    {t('common.archive')}
                  </Button>
                  <Button
                    hierarchy='secondary'
                    onClick={() => {
                      resetForm();
                      onClose();
                    }}
                  >
                    {workMaterialUuid ? t('common.discardChanges') : t('common.cancel')}
                  </Button>
                  <Button hierarchy='primary' type={'submit'} disabled={!!errors.title}>
                    {t('common.save')}
                  </Button>
                </div>
              </div>

              <BpCard header={{ headline: t('common.content') }}>
                <BpTipTap
                  className={styles.description}
                  defaultValue={values.description}
                  onChange={async (value) => {
                    await setFieldValue('description', value);
                  }}
                  name={'description'}
                  required={false}
                  placeholder={t('common.addType', { type: t('common.description') })}
                  error={errors.description}
                />
                {values.fileEntries && values.fileEntries.length > 0 && (
                  <FileTable
                    className={styles.list}
                    mode={'edit'}
                    files={values.fileEntries}
                    onRenamed={async (uuid, newName) => {
                      const updated = values.fileEntries.map((fileEntry) => {
                        if (fileEntry.uuid === uuid) {
                          return { ...fileEntry, filename: newName };
                        }
                        return fileEntry;
                      });
                      await setFieldValue('fileEntries', updated);
                    }}
                    onDeleted={async (uuid) => {
                      await setFieldValue(
                        'fileEntries',
                        values.fileEntries.filter((fileEntry) => {
                          return fileEntry.uuid !== uuid;
                        }),
                      );
                    }}
                    isGroupEditor={true}
                  />
                )}
                <FileUpload
                  onFileUpload={async (file) => {
                    await setFieldValue('fileEntries', [...values.fileEntries, file]);
                  }}
                />
              </BpCard>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
