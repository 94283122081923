import {
  Button,
  CoursesIcon,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Table,
  TableColumns,
  useDefaultSorting,
} from '@bp/ui-components';
import { Row } from '@tanstack/react-table';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from '../../hooks/useConfirm';
import { Dayjs } from 'dayjs';
import { getExternalService } from '../../utils/getExternalServiceHelper';
import { BpLink } from '../../components/BpLink/BpLink';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { SubmissionStatus, useBpAssignmentsCountQuery } from '../../client/bp-graphql-client-defs';

export type CourseTableType = {
  uuid: string;
  organizationUuid: string;
  organizationName?: string;
  name: string;
  meetingInProgress: boolean;
  count: number;
  newMessage: boolean;
  community: boolean;
  source: string;
  nextcloud: { lastSync?: Dayjs; enabled: boolean };
  threema: { lastSync?: Dayjs; enabled: boolean };
};

type CoursesTableTeacherProps = {
  data: CourseTableType[];
  onNavigate: (uuid: string) => void;
  onAdd: () => void;
  onEdit: (uuid: string) => void;
  onDelete: (uuid: string) => void;
  onSettings: (uuid: string) => void;
};

export const CoursesTableTeacher: FC<CoursesTableTeacherProps> = ({
  data,
  onNavigate,
  onAdd,
  onEdit,
  onDelete,
  onSettings,
}) => {
  const { t } = useTranslation();
  const permissionChecker = usePermissionChecker();

  const { sorting, onSortingChange } = useDefaultSorting();

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('courses.titleSingular'), context: 'male' }),
    defaultConfirmText: t('delete.delete'),
  });

  const canAdd = permissionChecker?.canCreateCourse();
  const canEdit = (row: Row<CourseTableType>) =>
    permissionChecker?.canUpdateCourse({ ...row.original, organization: { uuid: row.original.organizationUuid } });
  const canDelete = permissionChecker?.canDeleteCourse();

  const context = useMemoizedCacheTag('SUBMISSION');
  const [assignments] = useBpAssignmentsCountQuery({
    context: context,
    variables: {
      where: { uuid_IN: data.map((c) => c.uuid) },
      submissionWhere: {
        status: SubmissionStatus.New,
      },
    },
  });

  const dataWithSubmissionCount = data.map((course) => {
    const courseWithNewSubmissions = assignments.data?.groups
      .map((a) => {
        const todoCount = a.teachingUnitsConnection.edges.flatMap((e) => {
          const newSubmsns = e.node.assignments.map((a) => a.submissions.length);
          return newSubmsns.reduce((a, b) => a + b, 0);
        });
        return { courseUuid: a.uuid, count: todoCount.reduce((a, b) => a + b, 0) };
      })
      .find((g) => g.courseUuid === course.uuid);
    return { ...course, count: courseWithNewSubmissions?.count ?? 0 };
  });

  const getDropdownMenu = (row: Row<CourseTableType>): DropdownMenuItem[] => {
    const menu: DropdownMenuItem[] = [];

    if (canEdit(row)) {
      menu.push({
        label: t('common.edit'),
        onClick: () => onEdit(row.original.uuid),
      });
    }

    if (canEdit(row)) {
      menu.push({
        label: t('settings.title'),
        onClick: () => onSettings(row.original.uuid),
      });
    }

    if (canDelete) {
      menu.push(
        { type: 'ruler' },
        {
          label: t('delete.delete'),
          color: 'error',
          onClick: async () => {
            const res = await confirmDelete();
            if (res) {
              onDelete(row.original.uuid);
            }
          },
        },
      );
    }

    return menu;
  };

  const columns: TableColumns<CourseTableType>[] = useMemo(() => {
    return [
      {
        canExpand: true,
        header: t('common.designation'),
        id: 'name',
        accessorKey: 'name',
        cell: ({ row }: { row: Row<CourseTableType> }) => {
          return (
            <BpLink
              value={row.original.name}
              onNavigate={onNavigate ? () => onNavigate(row.original.uuid) : undefined}
              hasNews={row.original.newMessage}
              hasNewsHint={t('groups.newMessageHint')}
              inProgress={row.original.meetingInProgress}
              counter={row.original.count}
              maxWidth='620px'
              isEditorOfCourse={true}
            />
          );
        },
      },
      {
        header: t('common.community'),
        id: 'community',
        accessorKey: 'community',
        type: 'boolean',
        size: 100,
      },
      {
        header: t('common.managedBy'),
        id: 'source',
        accessorKey: 'source',
        size: 130,
      },
      {
        header: t('courses.nextcloud'),
        id: 'nextcloud',
        accessorKey: 'nextcloud',
        cell: ({ row }: { row: Row<CourseTableType> }) => {
          const service = getExternalService(row.original.nextcloud.enabled, row.original.nextcloud.lastSync);
          return <div style={{ color: service.color ?? undefined }}>{service.text.toString()}</div>;
        },
        size: 150,
      },
      {
        header: t('common.threema'),
        id: 'threema',
        accessorKey: 'threema',
        cell: ({ row }: { row: Row<CourseTableType> }) => {
          const service = getExternalService(row.original.threema.enabled, row.original.threema.lastSync);
          return <div style={{ color: service.color ?? undefined }}>{service.text.toString()}</div>;
        },
        size: 150,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-cy='courseList'>
      <Table<CourseTableType>
        data={dataWithSubmissionCount}
        columns={columns}
        canScroll
        showSort
        breakpoint='580px'
        sorting={sorting}
        onSortingChange={onSortingChange}
        showActionBar
        actionBarSettings={{
          showAddButton: canAdd,
          addButtonText: t('courses.addCourse'),
        }}
        hideHeader={dataWithSubmissionCount?.length === 0}
        isOnWhite={false}
        showBorderRadius
        showShadow
        onAddClick={() => onAdd()}
        emptyStateSettings={{
          icon: <CoursesIcon />,
          padding: 'xl',
        }}
        lastCol={(row: Row<CourseTableType>) => {
          const menu = getDropdownMenu(row);
          if (menu.length === 0) return;
          return (
            <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
              <DropdownMenu data={menu} />
            </Dropdown>
          );
        }}
      />

      <ConfirmationDialog />
    </div>
  );
};
