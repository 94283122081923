import { EventRecurrence } from '../client/bp-graphql-client-defs';
import { FileEntryTableType } from '../components/FileTable/FileTable';
import dayjs from 'dayjs';

export const CONNECT_DATE_TEMPLATE = 'YYYY-MM-DD' as const;

export const connectByUuid = (uuid: string | null | undefined) => {
  return {
    connect: {
      where: {
        node: {
          uuid: uuid,
        },
      },
    },
  };
};

export const bpProperty = (name: string, value: string) => {
  return {
    edge: {
      scope: 'bp',
    },
    node: {
      name: name,
      value: value,
    },
  };
};

export const globalProperty = (name: string, value: string) => {
  return {
    edge: {
      scope: 'global',
    },
    node: {
      name: name,
      value: value,
    },
  };
};

export const bpManaged = () => {
  return {
    edge: {
      scope: 'source',
    },
    node: {
      name: 'master',
      value: 'bp',
    },
  };
};

export const connectOrCreateByUuid = (uuid: string) => {
  return {
    connectOrCreate: {
      where: {
        node: {
          uuid,
        },
      },
      onCreate: {
        node: {
          uuid,
        },
      },
    },
  };
};

export const fileEntriesConnect = (fileEntries: FileEntryTableType[] | undefined) =>
  fileEntries && fileEntries.length > 0
    ? {
        connect: fileEntries?.map((file, index) => {
          return {
            where: { node: { uuid: file.uuid } },
            edge: { order: index },
          };
        }),
      }
    : {};

export const connectDay = (date: string) => {
  return {
    connect: {
      where: {
        node: {
          date: dayjs(date).format(CONNECT_DATE_TEMPLATE),
        },
      },
    },
  };
};

export const buildRecurrenceRule = (recurrence: string) => {
  let frequency: EventRecurrence = EventRecurrence.Daily;
  switch (recurrence) {
    case 'daily':
      frequency = EventRecurrence.Daily;
      break;
    case 'weekly':
      frequency = EventRecurrence.Weekly;
      break;
    case 'workdays':
      frequency = EventRecurrence.Workdays;
      break;
    case 'yearly':
      frequency = EventRecurrence.Yearly;
      break;
    default:
      return;
  }

  return {
    recurrenceRule: {
      create: {
        node: {
          frequency: frequency,
        },
      },
    },
  };
};
