import { useDaysQuery } from '../client/bp-graphql-client-defs';
import dayjs from 'dayjs';
import { useMemoizedCacheTag } from './useMemoizedCacheTag';
import { Day } from '@bp/bp-graphql-types';

export type DayType = Pick<Day, 'uuid' | 'date' | 'day' | 'month' | 'year' | 'dayOfWeek' | 'week' | 'schoolDay'>;

export const MAX_YEARS_IN_THE_PAST = 1;
export const MAX_DAYS = 500;

export const useDays = () => {
  const actualYear = dayjs().get('year');
  const limit = actualYear - MAX_YEARS_IN_THE_PAST;

  const context = useMemoizedCacheTag('DAY');
  const [daysQueryResult] = useDaysQuery({
    variables: {
      where: {
        year_GTE: limit,
      },
      options: {
        limit: MAX_DAYS,
      },
    },
    context,
  });
  const days: DayType[] | undefined = daysQueryResult.data?.days;

  const findDay = (date: Date) => {
    return days?.find((day) => dayjs(day.date).isSame(dayjs(date), 'day'));
  };

  const today = findDay(new Date());

  const isFuture = (day: DayType | undefined | null) => dayjs(day?.date).isAfter(today?.date, 'day');
  const isPast = (day: DayType | undefined | null) => dayjs(day?.date).isBefore(today?.date, 'day');
  const daysLeft = (day: DayType | undefined | null, returnNegative = false): number => {
    const remaining = dayjs(day?.date).diff(dayjs(today?.date), 'd');

    if (!returnNegative) {
      // return 0 when negative
      return remaining < 0 ? 0 : remaining;
    }

    // return any value
    return remaining;
  };

  return { today, daysLeft, isPast, isFuture };
};
