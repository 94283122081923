import {
  BpCalendarEventsQuery,
  useBpBirthdayEventsQuery,
  useBpCalendarEventsQuery,
} from '../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from './useMemoizedCacheTag';
import { useAuthClaims } from './useAuthClaims';
import { BpEventType } from '../components/EventsList/EventsList';
import { uniqueByUuid } from '../utils/uniquByUuid';
import { isNotEmpty } from '../utils/type-guards';
import dayjs from 'dayjs';
import { eventColors } from '../utils/dateCalculations';
import { useMemo } from 'react';
import { usePermissionChecker } from './usePermissionChecker';
import { RunningMeetingType } from 'components/RunningMeetingsTable/RunningMeetingsTable';
import { UseQueryExecute } from 'urql';
import { useDays } from './useDays';

export const useDashboardCalendarEvents = (
  profileUuid: string,
): { dashboardEvents: BpEventType[]; refetchDashboardEvents: () => void } => {
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const organization = pimAuthClaims.getOrganization();
  const context = useMemoizedCacheTag('EVENT');
  const birthdays = useBirthdays();

  const [{ data: eventData }, refetch] = useBpCalendarEventsQuery({
    variables: {
      where: {
        OR: [
          {
            holderConnection: {
              Organization: {
                node: { uuid: organization.uuid },
              },
            },
          },
          {
            attendees_SOME: {
              uuid: profileUuid,
            },
          },
          {
            owner: {
              uuid: profileUuid,
            },
          },
        ],
      },
    },
    context,
  });

  const events: BpEventType[] = mapCalendarEventsToBpEventType(
    [...(eventData?.calendarEvents ? eventData.calendarEvents : [])],
    profileUuid,
    permissions?.isOrganizationAdmin(organization) ?? false,
  );

  return {
    dashboardEvents: uniqueByUuid(
      [...events, ...birthdays].sort((a, b) => {
        const startA = new Date(a.start.toDate()).getTime();
        const startB = new Date(b.start.toDate()).getTime();
        return startA - startB;
      }),
    ),
    refetchDashboardEvents: refetch,
  };
};

export const useCourseCalendarEvents = (uuid: string): BpEventType[] => {
  const events = useGroupOrCourseCalendarEvents([uuid]);
  const birthdays = useBirthdays(undefined, uuid);
  return [...events, ...birthdays].sort((a, b) => {
    const startA = a.start.unix();
    const startB = b.start.unix();
    return startA - startB;
  });
};

export const useGroupOrCoursesOnlineEvents = (uuids: string[]): BpEventType[] => {
  const events = useGroupOrCourseCalendarEvents(uuids, true);
  return events.sort((a, b) => {
    const startA = a.start.unix();
    const startB = b.start.unix();
    return startA - startB;
  });
};

export const useGroupCalendarEvents = (uuid: string) => {
  const events = useGroupOrCourseCalendarEvents([uuid]);
  const birthdays = useBirthdays(uuid, undefined);
  return [...events, ...birthdays].sort((a, b) => {
    const startA = a.start.unix();
    const startB = b.start.unix();
    return startA - startB;
  });
};

const useGroupOrCourseCalendarEvents = (uuid: string[], onlyOnline: boolean = false): BpEventType[] => {
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const organization = pimAuthClaims.getOrganization();
  const context = useMemoizedCacheTag('EVENT');
  const [{ data }] = useBpCalendarEventsQuery({
    variables: {
      where: {
        holderConnection: {
          Group: {
            node: {
              uuid_IN: uuid,
            },
          },
        },
        ...(onlyOnline
          ? {
              virtualLocations_SOME: {
                running: true,
              },
            }
          : undefined),
      },
    },
    context,
  });

  return mapCalendarEventsToBpEventType(
    data?.calendarEvents ?? [],
    pimAuthClaims.getProfile().uuid,
    permissions?.isOrganizationAdmin(organization) ?? false,
  );
};

const useBirthdays = (groupUuid?: string, courseUuid?: string): BpEventType[] => {
  const [{ data }] = useBpBirthdayEventsQuery({ variables: { groupUuid: groupUuid, courseUuid: courseUuid } });

  return (
    data?.birthdayEvents
      .map((bE) => ({
        uuid: bE.uuid,
        title: bE.title ?? '',
        description: undefined,
        keywords: [],
        categories: [],
        type: 'birthday',
        color: eventColors(bE.categories ?? [], true),
        owner: false,
        guestsAllowed: false,
        start: dayjs(bE.start),
        attendees: [],
        locationsCount: 0,
        virtualLocationsCount: 0,
        locations: [],
        virtualLocations: [],
      }))
      .filter(isNotEmpty) ?? []
  );
};

export const useRunningPlannedMeetings = (
  profileUuid: string,
): { runningMeetings: BpEventType[]; plannedMeetings: BpEventType[]; refetchEvents: () => void } => {
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const organization = pimAuthClaims.getOrganization();
  const context = useMemoizedCacheTag('EVENT');

  const { today } = useDays();
  const [{ data: eventData }, refetch] = useBpCalendarEventsQuery({
    variables: {
      where: {
        AND: [
          {
            OR: [
              {
                virtualLocations_SOME: {
                  running: true,
                },
              },
              {
                daysConnection_SOME: { node: { uuid: today?.uuid } },
              },
            ],
          },
          {
            OR: [
              {
                holderConnection: {
                  Organization: {
                    node: { uuid: organization.uuid },
                  },
                },
              },
              {
                attendees_SOME: {
                  uuid: profileUuid,
                },
              },
              {
                owner: {
                  uuid: profileUuid,
                },
              },
            ],
          },
        ],
      },
    },
    context,
  });

  const { runningMeetings, plannedMeetings } = useMemo(() => {
    const plannedMeetings: BpEventType[] = [];
    const runningMeetings: BpEventType[] = [];

    mapCalendarEventsToBpEventType(
      eventData?.calendarEvents ?? [],
      profileUuid,
      permissions?.isOrganizationAdmin(organization) ?? false,
    )
      .filter(
        (e) =>
          e.virtualLocationsCount > 0 &&
          ((dayjs().add(1, 'days').isAfter(e.start) && dayjs().subtract(Number(e.duration), 's').isBefore(e.start)) ||
            e.virtualLocations.find((v) => v.running)?.running),
      )
      .forEach((e) => {
        if (e.virtualLocations.find((v) => v.running)) {
          runningMeetings.push(e);
        } else {
          plannedMeetings.push(e);
        }
      });

    return { runningMeetings, plannedMeetings };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData, refetch]);

  return {
    runningMeetings: runningMeetings,
    plannedMeetings: plannedMeetings,
    refetchEvents: () => refetch({ requestPolicy: 'network-only' }),
  };
};

export const mapCalendarEventsToBpEventType = (
  calendarEvents: BpCalendarEventsQuery['calendarEvents'],
  currentProfileUuid: string,
  isEventAdmin: boolean,
): BpEventType[] => {
  return calendarEvents?.map((e) => {
    const eventColor = eventColors(e.categories ?? []);

    const holderNode = e.holderConnection.edges.find((h) => (h.node.uuid ?? '').length > 0)?.node;

    return {
      uuid: e.uuid,
      guestsAllowed: e.guestsAllowed ?? true,
      maxAttendees: e.maxAttendees ?? 0,
      virtualLocationsCount: e.virtualLocations.length,
      virtualLocations:
        e.virtualLocations.map((v) => ({
          uuid: v.uuid,
          features: v.features ?? [],
          uri: import.meta.env.VITE_APP_REDIRECT_URI + '/join/' + v.token,
          running: v.running,
          token: v.token,
          currentAttendees: v.attendeesConnection.edges.length,
          currentVideoAttendees: v.attendeesConnection.edges.filter((e) => e.properties.withVideo).length,
        })) ?? [],
      owner: e.owner.uuid === currentProfileUuid || isEventAdmin,
      locationsCount: e.locations.length,
      classbookNote: e.classbookNote ?? undefined,
      classbookNoteContentType: e.classbookNoteContentType ?? undefined,
      classbookHomework: e.classbookHomework ?? undefined,
      classbookHomeworkContentType: e.classbookHomeworkContentType ?? undefined,
      attendees: e.attendeesConnection.edges.map((p) => {
        return { ...p.properties, ...p.node, displayName: p.node.displayName ?? '' };
      }),
      start: dayjs(e.start),
      color: eventColor,
      type: 'event',
      categories: e.categories ?? [],
      description: e.description ?? '',
      title: e.title ?? '',
      duration: e.duration ?? undefined,
      keywords: e.keywords ?? [],
      locale: e.locale ?? '',
      status: e.status ?? '',
      originDisplayName: holderNode?.displayName ?? '',
      originUuid: holderNode?.uuid ?? '',
      originType: holderNode?.__typename === 'Group' ? holderNode.type : holderNode?.__typename,
    };
  });
};

export const mapCalendarEventsToRunningMeetings = (
  calendarEvents: BpCalendarEventsQuery['calendarEvents'],
): RunningMeetingType[] => {
  return calendarEvents.flatMap((event) => {
    const holderNode = event.holderConnection.edges.find((h) => (h.node.uuid ?? '').length > 0)?.node;
    return event.virtualLocations.flatMap((location) => {
      return {
        eventUuid: event.uuid,
        originUuid: holderNode?.uuid ?? '',
        locationUuid: location.uuid,
        running: location.running,
        origin: holderNode?.__typename === 'Group' ? holderNode.type : holderNode?.__typename,
        originName: holderNode?.displayName ?? '',
        start: dayjs(event.start).format('DD.MM.YY, HH:mm'),
        attendees: location.attendeesConnection.edges.length,
        withVideo: location.attendeesConnection.edges.filter((e) => e.properties.withVideo).length,
        maxAttendees: event.maxAttendees ?? 0,
        uri: import.meta.env.VITE_APP_REDIRECT_URI + '/join/' + location.token,
        token: location.token,
      } as RunningMeetingType;
    });
  });
};

export const useRunningOrganizationMeetings = (): {
  refetch: UseQueryExecute;
  bbbMeetings: RunningMeetingType[];
  zoomMeetings: RunningMeetingType[];
} => {
  const [{ data }, refetch] = useBpCalendarEventsQuery({
    variables: {
      where: {
        start_CONTAINS: dayjs().startOf('day').format('YYYY-MM-DD'),
      },
    },
    context: useMemoizedCacheTag('EVENT'),
  });

  return {
    refetch,
    bbbMeetings: mapCalendarEventsToRunningMeetings(
      data?.calendarEvents.filter((e) => e.categories?.includes('bbb')) ?? [],
    ),
    zoomMeetings: mapCalendarEventsToRunningMeetings(
      data?.calendarEvents.filter((e) => e.categories?.includes('zoom')) ?? [],
    ),
  };
};
