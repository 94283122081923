import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EmptyStateSettings,
  ImportIcon,
  Modal,
  Table,
  useDefaultSorting,
} from '@bp/ui-components';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useBpDeleteFileEntryMutation } from '../../client/bp-graphql-client-defs';
import { mimeTypes } from '../../utils/mime-types-map';
import { formatBytes } from '../../utils/formatBytes';
import { getFileUrl } from '../../utils/getFileUrl';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { FileRenameForm } from '../FileRenameForm/FileRenameForm';
import { handleFileEntryDownload } from '../../utils/download';
import { BpLink } from '../BpLink/BpLink';
import { useConfirm } from 'hooks/useConfirm';
import { type FileEntry } from '@bp/bp-graphql-types';

type FileTableProps = {
  mode: 'show' | 'edit';
  files: FileEntryTableType[];
  isGroupEditor: boolean;
  className?: string | undefined;
  onDeleted?: (uuid: string) => void;
  onRenamed?: (uuid: string, newName: string) => void;
  breakpoint?: string;
  padding?: string;
  emptyStateSettings?: EmptyStateSettings;
};

export type FileEntryTableType = Omit<
  FileEntry,
  'organizationConnection' | 'holder' | 'holderConnection' | 'organization' | 'ownerConnection' | 'owner'
>;
export const FileTable: FC<FileTableProps> = ({
  mode,
  className,
  files,
  onDeleted,
  onRenamed,
  breakpoint,
  padding = 'var(--spacing-6)',
  emptyStateSettings,
  isGroupEditor,
}) => {
  const { t } = useTranslation();
  const [, copyToClipboard] = useCopyToClipboard();

  const context = useMemoizedCacheTag('FILE_ENTRY');

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('files.titleSingular'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  const [, deleteFile] = useBpDeleteFileEntryMutation();
  const onFileDelete = async (uuid: string) => {
    await deleteFile({ where: { uuid: uuid } }, context);
    onDeleted && onDeleted(uuid);
  };

  const filesWithMimeType: FileEntryTableType[] =
    files?.map((file) => {
      return {
        ...file,
        mediaType: mimeTypes[file.mediaType] ? mimeTypes[file.mediaType] : file.mediaType,
        formatSize: formatBytes(file.size),
      };
    }) ?? [];

  /* RenameModal */
  const [isOpen, setIsOpen] = React.useState(false);
  const [file, setFile] = React.useState<FileEntryTableType | null>(null);

  const handleModalClose = () => {
    setFile(null);
    setIsOpen(false);
  };

  function openModal(file: FileEntryTableType) {
    setFile(file);
    setIsOpen(true);
  }

  const { sorting, onSortingChange } = useDefaultSorting([{ id: 'filename', desc: false }]);

  const fileDropdownEditor = (file: FileEntryTableType): DropdownMenuItem[] => [
    {
      onClick: async () => {
        if (file.downloadToken) {
          await handleFileEntryDownload(file);
        }
      },
      label: t('common.download'),
    },
    {
      onClick: () => {
        openModal(file);
      },
      label: t('common.rename'),
    },
    {
      onClick: async () => {
        const currentFile = files?.find((fe) => fe.uuid === file.uuid);
        if (currentFile && currentFile.publicDownloadToken) {
          await copyToClipboard(getFileUrl(currentFile.uuid, currentFile.publicDownloadToken));
          showSuccessToast(t('common.copySuccess'));
        }
      },
      label: t('common.copyType', { type: 'Link' }),
    },
    { type: 'ruler' },
    {
      label: t('delete.delete'),
      color: 'error',
      onClick: async () => {
        const res = await confirmDelete();
        if (res) {
          await onFileDelete(file.uuid);
        }
      },
    },
  ];

  return (
    <>
      <div className={className}>
        <Table<FileEntryTableType>
          maxHeight={210}
          canScroll
          customPadding={padding}
          breakpoint={breakpoint}
          data={filesWithMimeType}
          columns={[
            {
              header: t('common.filename'),
              accessorKey: 'filename',
              id: 'filename',
              size: 400,
              canExpand: true,
              cell: ({ row }) => {
                return (
                  <BpLink
                    value={row.original.filename}
                    onNavigate={async () => {
                      await handleFileEntryDownload(row.original);
                    }}
                    isEditorOfCourse={isGroupEditor}
                  />
                );
              },
            },
            {
              header: t('common.mediaType'),
              accessorKey: 'mediaType',
              id: 'mediaType',
              size: 60,
            },
            {
              header: t('common.size'),
              accessorKey: 'formatSize',
              id: 'formatSize',
              size: 100,
            },
          ]}
          hideHeader={filesWithMimeType.length === 0}
          emptyStateSettings={
            emptyStateSettings ?? {
              title: '',
              size: 'small',
              subtitle: t('files.noFiles'),
              padding: 'l',
            }
          }
          showSort
          sorting={sorting}
          onSortingChange={onSortingChange}
          lastColWidth='var(--spacing-6)'
          lastCol={(row) => {
            const currentFile = files?.find((fe) => fe.uuid === row.original.uuid);

            return (
              <div>
                {mode === 'show' && !!currentFile && (
                  <Button
                    hierarchy='ghost'
                    icon={
                      <ImportIcon
                        onClick={async () => {
                          if (currentFile && currentFile.downloadToken) {
                            await handleFileEntryDownload(currentFile);
                          }
                        }}
                      />
                    }
                  />
                )}
                {mode === 'edit' && !!onFileDelete && (
                  <Dropdown
                    usePortal={false}
                    noPadding
                    trigger={<Button hierarchy='ghost' icon={<DotsHorizontalIcon />} />}
                  >
                    <DropdownMenu data={fileDropdownEditor(row.original)} />
                  </Dropdown>
                )}
              </div>
            );
          }}
        />
        {isOpen && file && (
          <Modal isOpen={isOpen} onRequestClose={handleModalClose} title={t('common.rename')} width='s'>
            <FileRenameForm
              file={file}
              onClose={handleModalClose}
              onRenamed={(uuid, newName) => {
                onRenamed && onRenamed(uuid, newName);
              }}
            />
          </Modal>
        )}
      </div>

      <ConfirmationDialog />
    </>
  );
};
