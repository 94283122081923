import styles from './AlertDialog.module.scss';
import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

type AlertDialogProps = PropsWithChildren & {
  title: string;
  actions: ReactNode;
  isOpen: boolean;
};

export const AlertDialog = ({ title, actions, children, isOpen }: AlertDialogProps) => {
  const classes = classNames(styles['overlay'], { [styles['is-open']]: isOpen });

  return (
    <div className={classes}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{children}</div>
        <div className={styles.actions}>{actions}</div>
      </div>
    </div>
  );
};
