import { FC } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, GridRow, Input, TextArea } from '@bp/ui-components';
import { ModalBottomButtons } from 'components/ModalBottomButtons/ModalBottomButtons';

export type AnnouncementFormType = {
  title: string;
  message: string;
};

type AnnouncementFormProps = {
  onSubmit: (values?: AnnouncementFormType) => void;
};

export const AnnouncementForm: FC<AnnouncementFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const initialValues: AnnouncementFormType = {
    title: '',
    message: '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, errors, resetForm, handleChange }) => {
        return (
          <Form className='bp__form'>
            <Grid>
              <GridRow spacingBottom='s'>
                <Input onChange={handleChange} name='title' value={values.title} label={t('common.title')} />
              </GridRow>
              <GridRow spacingTop='s'>
                <TextArea
                  onChange={handleChange}
                  name='message'
                  rows={3}
                  value={values.message}
                  label={t('common.description')}
                />
              </GridRow>
            </Grid>
            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  resetForm();
                  onSubmit();
                },
              }}
              submitButton={{
                text: t('common.publish'),
              }}
              isLoading={false}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
