import { useNavigate, useParams } from 'react-router-dom';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { FC } from 'react';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { useBpDeleteWorkMaterialsMutation, useBpTeachingUnitsQuery } from '../../../client/bp-graphql-client-defs';
import { niceDate } from '../../../utils/dateCalculations';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { showErrorToast } from '../../../utils/showErrorToast';
import { showSuccessToast } from '../../../utils/showSuccessToast';
import { t } from 'i18next';
import { MaterialDetail } from '../../../components/MaterialDetail/MaterialDetail';
import { GroupWorkMaterialsForm } from '../../../components/WorkMaterialsForm/GroupWorkMaterialsForm';
import { GroupWorkMaterialsTable } from '../../../components/WorkMaterialsTable/GroupWorkMaterialsTable';
import { ErrorState } from '@bp/ui-components';
import { NotificationReasons } from '../../../utils/matrixClient';
import { backendApi } from '../../../utils/backendApi';

export const GroupWorkMaterialsSubpage: FC<{ variant: 'add' | 'edit' | 'detail' | 'table' }> = ({ variant }) => {
  const { uuid, groupUuid } = useParams<{ add: string; edit: string; uuid: string; groupUuid: string }>();
  const navigate = useNavigate();
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();

  const context = useMemoizedCacheTag('WORKMATERIAL');

  const [{ data }] = useBpTeachingUnitsQuery({
    variables: {
      teachingUnitsWhere: {
        group: {
          uuid: groupUuid,
        },
      },
    },
    context,
  });

  const teachingUnitUuid = data?.teachingUnits[0]?.uuid;
  const workmaterial = data?.teachingUnits[0]?.workMaterials;

  const hasPermission = groupUuid
    ? permissions?.canCreateWorkmaterial({
        uuid: groupUuid,
        organization: { uuid: pimAuthClaims.getOrganization().uuid },
      })
    : false;

  const [, deleteWorkMaterial] = useBpDeleteWorkMaterialsMutation();

  const handleDelete = async (uuid: string, title: string) => {
    const res = await deleteWorkMaterial(
      {
        where: {
          uuid,
        },
      },
      context,
    );
    if (res.error) {
      showErrorToast(res.error);
    } else {
      showSuccessToast(t('common.success'));
      void backendApi.notify({
        type: NotificationReasons.MaterialDeleted,
        groupUuid: groupUuid ?? '',
        subjectName: title,
      });
    }
  };

  const handleClose = async (uuid?: string, title?: string) => {
    if (groupUuid) {
      // send matrix notification
      const notificationBody: {
        type: NotificationReasons;
        subjectUuid?: string;
        groupUuid: string;
        subjectName: string;
      } = {
        type: variant === 'add' ? NotificationReasons.NewGroupMaterial : NotificationReasons.GroupMaterialUpdated,
        groupUuid: groupUuid,
        subjectName: title ?? '',
      };

      if (variant === 'edit' && uuid) {
        notificationBody.subjectUuid = uuid;
      }

      void backendApi.notify(notificationBody);
    }
    navigate(`/groups/${groupUuid}/workmaterials/`);
  };

  return !hasPermission && (variant === 'edit' || variant === 'add') ? (
    <ErrorState type={'forbidden'} onNavigateBack={() => navigate(`/groups/${groupUuid}/workmaterials/`)} />
  ) : (!teachingUnitUuid || !groupUuid) && (variant === 'edit' || variant === 'add') ? (
    <ErrorState onNavigateBack={() => navigate(`/groups/${groupUuid}/workmaterials/`)} />
  ) : (
    <BpSubpage>
      {(variant === 'edit' || variant === 'add') && teachingUnitUuid && groupUuid && hasPermission && (
        <GroupWorkMaterialsForm
          teachingUnitUuid={teachingUnitUuid}
          canArchive={variant === 'edit'}
          onClose={(uuid, title) => handleClose(uuid, title)}
          workMaterialUuid={uuid ? uuid : undefined}
        />
      )}
      {variant === 'detail' && uuid && (
        <MaterialDetail onClose={() => navigate(`/groups/${groupUuid}/workmaterials/`)} uuid={uuid ?? ''} />
      )}
      {variant === 'table' && groupUuid && (
        <GroupWorkMaterialsTable
          groupUuid={groupUuid}
          onAdd={() => {
            navigate(`/groups/${groupUuid}/workmaterials/add`);
          }}
          onEdit={(uuid) => {
            navigate(`/groups/${groupUuid}/workmaterials/${uuid}/edit`);
          }}
          onDelete={async (uuid, title) => {
            await handleDelete(uuid, title);
          }}
          onShow={(uuid) => {
            navigate(`/groups/${groupUuid}/workmaterials/${uuid}`);
          }}
          data={
            workmaterial?.map((wm) => {
              return {
                uuid: wm.uuid,
                title: wm.title,
                created: niceDate(wm.updated, 'long', 'short') ?? '',
                filesCount: wm.material.fileEntries.length,
                creator: wm.owner.displayName ?? '',
              };
            }) ?? []
          }
        />
      )}
    </BpSubpage>
  );
};
