import { useTranslation } from 'react-i18next';
import { FC, useCallback, useMemo } from 'react';
import {
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { BpLink } from '../BpLink/BpLink';

type GroupWorkMaterialType = {
  uuid: string;
  title: string;
  filesCount: number;
  created: string;
  creator: string;
};

type GroupWorkMaterialsTableProps = {
  data: GroupWorkMaterialType[];
  groupUuid: string;
  onAdd: () => void;
  onEdit: (uuid: string) => void;
  onDelete: (uuid: string, title: string) => void;
  onShow: (uuid: string) => void;
};

export const GroupWorkMaterialsTable: FC<GroupWorkMaterialsTableProps> = ({
  groupUuid,
  data,
  onAdd,
  onEdit,
  onDelete,
  onShow,
}) => {
  const { t } = useTranslation();
  const permissions = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedColumns = useMemo(createColumns, [onShow]);

  const memoizedData = useMemo((): GroupWorkMaterialType[] => {
    return data;
  }, [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedDropdown = useCallback((row: Row<GroupWorkMaterialType>) => createDropdown(row), []);

  function createColumns(): TableColumns<GroupWorkMaterialType>[] {
    return [
      {
        id: 'title',
        size: 450,
        header: t('common.name'),
        accessorKey: 'title',
        cell: ({ row }) => {
          return (
            <BpLink
              value={row.original.title}
              onNavigate={() => onShow(row.original.uuid)}
              isEditorOfCourse={
                // TODO: get Organization from group
                permissions?.isGroupEditor({ uuid: groupUuid, organization: pimAuthClaims.getOrganization() }) ?? false
              }
            />
          );
        },
      },
      {
        id: 'filesCount',
        size: 100,
        accessorKey: 'filesCount',
        header: t('common.documents'),
      },
      {
        id: 'created',
        size: 300,
        accessorKey: 'created',
        header: t('common.createdAt'),
      },
      {
        id: 'creator',
        size: 300,
        accessorKey: 'creator',
        header: t('common.createdBy'),
      },
    ];
  }

  function createDropdown(row: Row<GroupWorkMaterialType>): DropdownMenuItem[] {
    return [
      {
        label: t('common.edit'),
        onClick: () => {
          onEdit(row.original.uuid);
        },
      },
      {
        label: t('messages.addFile'),
      },
      {
        type: 'ruler',
      },
      {
        color: 'error',
        label: t('delete.delete'),
        onClick: () => onDelete(row.original.uuid, row.original.title),
      },
    ];
  }

  const canEdit = groupUuid
    ? permissions?.canCreateBpGroupMaterial({
        uuid: groupUuid,
        organization: { uuid: pimAuthClaims.getOrganizationUuid() },
      })
    : false;

  return (
    <Table<GroupWorkMaterialType>
      data={memoizedData}
      columns={memoizedColumns}
      hideHeader={memoizedData.length === 0}
      showBorderRadius
      showShadow
      showActionBar
      isOnWhite={false}
      actionBarSettings={{
        showAddButton: canEdit,
      }}
      breakpoint='580px'
      onAddClick={() => onAdd()}
      customLastColSpacing='var(--spacing-4)'
      emptyStateSettings={{
        title: t('workmaterials.noWorkmaterials'),
        subtitle: t('workmaterials.createHint'),
        padding: 'xl',
        hideIcon: true,
      }}
      lastCol={(row) => {
        return canEdit ? (
          <Dropdown noPadding trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon />} />}>
            <DropdownMenu data={memoizedDropdown(row)} />
          </Dropdown>
        ) : undefined;
      }}
    />
  );
};
