import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Assignment, GroupType, Submission } from '@bp/bp-graphql-types';
import {
  SortDirection,
  SubmissionStatus,
  useBpAssignmentsQuery,
  useBpDeleteAssignmentsMutation,
  useBpSubmissionsQuery,
  useGroupAsCourseQuery,
} from '../../../../client/bp-graphql-client-defs';
import styles from './TeacherContent.module.scss';
import {
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  GenerateIcon,
  ImproveIcon,
  NotificationDefaultIcon,
  Tab,
} from '@bp/ui-components';
import { AssignmentTab } from '../../../../components/AssignmentAsTeacherTabs/AssignmentTab';
import { SubmissionsTab } from '../../../../components/AssignmentAsTeacherTabs/SubmissionsTab';
import { useMemoizedCacheTag } from '../../../../hooks/useMemoizedCacheTag';
import { useParams } from 'react-router-dom';
import { DesktopContext } from '../../../../context/IsDesktop';
import classNames from 'classnames';
import { BpHeader } from 'components/BpHeader/BpHeader';
import { showErrorToast } from 'utils/showErrorToast';
import { showSuccessToast } from 'utils/showSuccessToast';
import { useConfirm } from '../../../../hooks/useConfirm';
import { usePermissionChecker } from '../../../../hooks/usePermissionChecker';
import { useDays } from '../../../../hooks/useDays';
import { useMatrixClient } from '../../../../hooks/matrix/useMatrixClient';

type CourseAssignmentTeacherContentProps = {
  assignmentUuid: string;
  onEdit: () => void;
  onSubmissionClick: (submission: Submission) => void;
  navigate: (to: string) => void;
};

export const CourseAssignmentTeacherContent: FC<CourseAssignmentTeacherContentProps> = ({
  assignmentUuid,
  onEdit,
  onSubmissionClick,
  navigate,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useContext(DesktopContext);
  const { courseUuid } = useParams();
  const matrixClient = useMatrixClient();

  const { daysLeft, isPast, isFuture } = useDays();

  const perms = usePermissionChecker();

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('assignments.titleSingular'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  const [{ data }] = useBpAssignmentsQuery({
    variables: {
      where: {
        uuid: assignmentUuid,
      },
    },
  });

  const submissionContext = useMemoizedCacheTag('SUBMISSION');
  const [submissionsQueryResult] = useBpSubmissionsQuery({
    context: submissionContext,
    variables: {
      options: {
        sort: [
          {
            updated: SortDirection.Desc,
          },
        ],
      },
      where: {
        assignment_SOME: {
          uuid: assignmentUuid,
        },
      },
    },
  });

  const context = useMemoizedCacheTag('GROUP');
  const [{ data: groupData }] = useGroupAsCourseQuery({
    context,
    variables: { where: { uuid: courseUuid ?? '', type: GroupType.Course } },
  });

  const submissions = submissionsQueryResult.data?.submissions;

  const submissionsNew = submissions?.filter((s) => s.status === SubmissionStatus.New);
  const submissionsTodo = submissions?.filter(
    (s) =>
      s.status === SubmissionStatus.Todo ||
      s.status === SubmissionStatus.Draft ||
      s.status === SubmissionStatus.Feedback,
  );

  const viewers = groupData?.groups[0].viewers;
  const isFutureAssignment = isFuture(data?.assignments[0]?.visibleFrom);
  const isPastAssignment = isPast(data?.assignments[0]?.dueDate);

  const contextAssignment = useMemoizedCacheTag('ASSIGNMENT');
  const [, deleteAssignment] = useBpDeleteAssignmentsMutation();

  const handleDelete = async () => {
    const res = await confirmDelete();
    if (res) {
      const assignment = data?.assignments[0];
      if (
        assignment &&
        perms?.canDeleteAssignment({
          uuid: assignmentUuid,
          ownerUuid: assignment.holder.owner.uuid,
          groupUuid: assignment.holder.group.uuid,
          organization: assignment.holder.organization,
        })
      ) {
        const { error } = await deleteAssignment(
          {
            where: { uuid: assignmentUuid },
          },
          contextAssignment,
        );

        error && showErrorToast(error);
        if (!error) {
          showSuccessToast(t('delete.deleted', { type: t('assignments.titleSingular') }));

          courseUuid &&
            matrixClient?.sendNotice(
              courseUuid,
              JSON.stringify({
                body: {
                  type: 'assignmentUpdated',
                  subjectName: assignment.title,
                  groupUuid: courseUuid,
                },
                msgtype: 'm.notice',
              }),
            );

          navigate(`/courses/${courseUuid}/assignments/`);
        }
      }
    }
  };

  return (
    <div className={styles['course-assignment-teacher']}>
      <div className={styles.header}>
        <BpHeader
          noMargin
          headline={data?.assignments[0]?.title ?? t('common.noTitle')}
          actions={[
            {
              hierarchy: isDesktop ? 'primary' : 'secondary',
              icon: isDesktop ? undefined : <EditIcon />,
              callback: () => onEdit(),
              text: isDesktop ? t('common.edit') : '',
            },
            {
              icon: <DeleteIcon />,
              callback: handleDelete,
              hierarchy: 'tertiary',
            },
          ]}
        />
      </div>
      <div className={styles.columns}>
        <div className={classNames(styles['overview-card'], styles.dark)}>
          <div className={styles.top}>
            {isDesktop && (
              <div className={styles.icon}>
                <NotificationDefaultIcon className={'large svg-icon white large'} />
              </div>
            )}
            {/*TODO questions count*/}
            <div className={styles.count}>0</div>
            <div className={styles.title}>{t('submissions.newQuestions')}</div>
          </div>
          <div className={styles.bottom}>
            {isDesktop && (
              <div className={styles.icon}>
                <GenerateIcon className={'large svg-icon white large'} />
              </div>
            )}
            <div className={styles.count}>{submissionsNew?.length ?? 0}</div>
            <div className={styles.title}>{t('submissions.newSubmissions')}</div>
          </div>
        </div>

        <div className={styles['overview-card']}>
          <div className={styles.top}>
            {isDesktop && (
              <div className={styles.icon}>
                <ImproveIcon className={'large svg-icon large'} />
              </div>
            )}
            <div className={styles.count}>
              {submissionsTodo?.length}/{submissions?.length}
            </div>
            <div className={styles.title}>{t('common.pending')}</div>
          </div>
          <div className={styles.bottom}>
            {isDesktop && (
              <div className={styles.icon}>
                <CalendarIcon className={'large svg-icon large'} />
              </div>
            )}
            {isFutureAssignment || isPastAssignment ? (
              <div className={styles.inactive}>
                {isFutureAssignment && t('assignments.notYetActive')}
                {isPastAssignment && t('assignments.finished')}
              </div>
            ) : (
              <>
                <div className={styles.count}>{daysLeft(data?.assignments[0]?.dueDate)}</div>
                <div className={styles.title}>{t('common.daysLeft')}</div>
              </>
            )}
          </div>
        </div>

        <div className={classNames(styles['overview-card'], styles.unit)}>
          <div className={styles.top}>
            <div className={styles.title}>{t('teachingUnits.titleSingular') + ':'}</div>
            <div className={styles.subject}>{data?.assignments[0]?.holder?.title ?? t('common.noTitle')}</div>
          </div>
        </div>
      </div>

      <Tab
        tabs={[
          {
            content: (
              <SubmissionsTab
                submissions={submissions as Submission[]}
                profiles={viewers}
                openSubmission={onSubmissionClick}
              />
            ),
            title: t('submissions.title'),
            value: 'submissions',
          },
          {
            content: <AssignmentTab data={data?.assignments[0] as Assignment} />,
            title: t('assignments.titleSingular'),
            value: 'assignments',
          },
        ]}
      />

      <ConfirmationDialog />
    </div>
  );
};
