import { AddIcon, CheckIcon, Grid, GridColumn, GridRow, Modal, SpinnerIcon } from '@bp/ui-components';
import {
  useBpGetAssignmentsByGroupQuery,
  useBpTeachingUnitsByGroupQuery,
  useGroupAsCourseQuery,
} from '../../../../client/bp-graphql-client-defs';
import { BpCard } from '../../../../components/BpCard/BpCard';
import { WorkMaterialsList, WorkMaterialType } from '../../../../components/WorkMaterialsList/WorkMaterialsList';
import { usePermissionChecker } from '../../../../hooks/usePermissionChecker';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isBefore, niceDate } from '../../../../utils/dateCalculations';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { OpenAssignmentsOverview } from '../../../../components/OpenAssignmentsOverview/OpenAssignmentsOverview';
import { AnnouncementsList } from '../../../../components/AnnouncementsList/AnnouncementsList';
import { useGetMatrixRoomByName } from '../../../../hooks/matrix/useGetMatrixRoomByName';
import { NotificationReasons, RoomNames } from '../../../../utils/matrixClient';
import { useGetAnnouncements } from '../../../../hooks/matrix/useGetAnnouncements';
import { AnnouncementForm, AnnouncementFormType } from '../../../../components/AnnouncementForm/AnnouncementForm';
import { useMemoizedCacheTag } from '../../../../hooks/useMemoizedCacheTag';
import { useMarkAsRead } from '../../../../hooks/matrix/useMarkAsRead';
import { NotificationCountType } from 'matrix-js-sdk';
import { useDays } from '../../../../hooks/useDays';
import { GroupType } from '@bp/bp-graphql-types';
import { backendApi } from '../../../../utils/backendApi';

type CourseOverviewStudentContentProps = {
  courseUuid: string;
};

export const CourseOverviewStudentContent = ({ courseUuid }: CourseOverviewStudentContentProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const perms = usePermissionChecker();

  const navigate = useNavigate();
  const [announcementModal, setAnnouncementModal] = useState(false);

  const courseContext = useMemoizedCacheTag('COURSE');
  const { markAsRead } = useMarkAsRead();
  const { getRoom } = useGetMatrixRoomByName();

  const [{ data: courseData }] = useGroupAsCourseQuery({
    context: courseContext,
    variables: { where: { uuid: courseUuid ?? '', type: GroupType.Course } },
  });

  const handleAnnouncementFormSubmit = (values?: AnnouncementFormType) => {
    setAnnouncementModal(false);
    values &&
      void backendApi.notify({
        type: NotificationReasons.Announcement,
        content: values.message,
        title: values.title,
        groupUuid: courseUuid,
      });
  };

  const currentCourse = useMemo(() => {
    return courseData?.groups[0];
  }, [courseData]);

  const room = getRoom(`${currentCourse?.organization.uuid}_${courseUuid}`, RoomNames.Group);
  const announcements = useGetAnnouncements(room);

  const _hasUnread = room ? room.getRoomUnreadNotificationCount(NotificationCountType.Total) > 0 : false;
  const [hasUnread, setHasUnread] = useState<boolean>(false);

  useEffect(() => {
    setHasUnread(_hasUnread);
  }, [_hasUnread]);

  const [{ data: assignmentsQueryResult }] = useBpGetAssignmentsByGroupQuery({
    context: courseContext,
    variables: {
      where: {
        uuid: courseUuid,
      },
      teachingUnitWhere: { active: true },
      submissionWhere: { owner: { uuid: pimAuthClaims.getProfile().uuid } },
    },
  });

  const [workMaterials] = useBpTeachingUnitsByGroupQuery({
    context: courseContext,
    variables: {
      where: {
        uuid: courseUuid,
      },
      // TODO: re-add sorting!
      // options: {
      //   sort: [
      //     {
      //       visibleTo: SortDirection.Asc,
      //     },
      //   ],
      // },
    },
  });

  const groupSubject = { uuid: courseUuid ?? '', organization: { uuid: currentCourse?.organization.uuid ?? '' } };

  const materials = workMaterials.data?.groups
    ?.map((g) => {
      return g.teachingUnits.map((t) =>
        t.workMaterials.map((item) => {
          return {
            ...item,
            timeFrame: `${niceDate(item.visibleFrom, 'short')} - ${niceDate(item.visibleTo, 'short')}`,
            holder: { uuid: t.uuid, title: t.title },
          };
        }),
      );
    })
    .flat(2);

  const activeMaterials: WorkMaterialType[] = [];
  const { daysLeft } = useDays();

  if (materials && materials.length > 0) {
    materials.forEach((a) => {
      const daysDiffVisibleTo: number = daysLeft(a.visibleTo, true);
      const daysDiffVisibleFrom: number = daysLeft(a.visibleFrom, true);
      if (daysDiffVisibleFrom < 1 && daysDiffVisibleTo > -1) {
        activeMaterials.push(a);
      }
    });
  }

  return (
    <Grid>
      <GridRow>
        <BpCard noPadding>
          <OpenAssignmentsOverview
            assignments={
              assignmentsQueryResult?.groups.flatMap((g) =>
                g?.teachingUnits
                  ?.map((t) => t.assignments)
                  .flatMap((as) => {
                    return as.map((a) => a);
                  })
                  .filter((a) => isBefore(new Date(), a.dueDate?.date)),
              ) ?? []
            }
            onNavigate={(uuid) => navigate(`/courses/${courseUuid}/assignments/${uuid}`)}
          />
        </BpCard>
      </GridRow>
      <GridRow>
        <GridColumn width={6}>
          <BpCard
            fixedHeight='374px'
            noPadding
            header={{
              headline: t('workmaterials.title'),
              actions: perms?.canCreateCourseAnnouncement(groupSubject)
                ? [
                    {
                      text: t('notifications.new'),
                      icon: <AddIcon />,
                      callback: () => console.log('new notification clicked'),
                    },
                  ]
                : undefined,
            }}
          >
            <WorkMaterialsList
              onNavigate={(uuid) => navigate(`/courses/${courseUuid}/workmaterials/${uuid}`)}
              workMaterial={activeMaterials}
              isGroupEditor={currentCourse ? (perms?.isCourseEditor(currentCourse) ?? false) : false}
            />
          </BpCard>
        </GridColumn>
        <GridColumn width={6}>
          <BpCard
            canScroll
            fixedHeight='374px'
            noPadding
            header={{
              headline: t('announcements.title'),
              actions: perms?.canCreateCourseAnnouncement(groupSubject)
                ? hasUnread
                  ? [
                      {
                        text: t('common.mark'),
                        hierarchy: 'ghost',
                        icon: <CheckIcon />,
                        callback: async () => {
                          setHasUnread(false);
                          await markAsRead(room);
                        },
                      },
                      {
                        text: t('announcements.new'),
                        icon: <AddIcon />,
                        callback: () => {
                          setAnnouncementModal(true);
                        },
                      },
                    ]
                  : [
                      {
                        text: t('announcements.new'),
                        icon: <AddIcon />,
                        callback: () => {
                          setAnnouncementModal(true);
                        },
                      },
                    ]
                : hasUnread
                  ? [
                      {
                        text: t('common.mark'),
                        hierarchy: 'primary',
                        icon: <CheckIcon />,
                        callback: async () => {
                          setHasUnread(false);
                          await markAsRead(room);
                        },
                      },
                    ]
                  : undefined,
            }}
          >
            <AnnouncementsList
              announcements={announcements}
              hasPermissionToDelete={perms?.canCreateCourseAnnouncement(groupSubject) ?? false}
            />
          </BpCard>
        </GridColumn>
      </GridRow>

      <Modal
        isOpen={announcementModal}
        onRequestClose={() => setAnnouncementModal(false)}
        isFormModal
        hideFooter
        title={t('announcements.new')}
        width='s'
      >
        <Suspense fallback={<SpinnerIcon className={'svg-icon large spinning'} />}>
          <AnnouncementForm onSubmit={handleAnnouncementFormSubmit} />
        </Suspense>
      </Modal>
    </Grid>
  );
};
