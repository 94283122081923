import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SettingsSubpages.module.scss';
import {
  Button,
  Checkbox,
  DotsVerticalIcon,
  Grid,
  GridColumn,
  GridRow,
  Select,
  SelectOptionType,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { useBpAdminProfilesQuery, useUpdateBildungsplattformsMutation } from '../../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { BookingsModal } from '../../../components/Modals/BookingsModal/BookingsModal';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { niceDate } from '../../../utils/dateCalculations';
import { BpCard } from '../../../components/BpCard/BpCard';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { adminRolesAsString } from '../../../utils/adminRolesAsString';
import { OrganizationConfigContext } from '../../../context/OrganizationConfigContext';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { showErrorToast } from '../../../utils/showErrorToast';
import { showSuccessToast } from '../../../utils/showSuccessToast';

export const Products = {
  BILDUNGSPLATTFORM: 'Bildungsplattform',
  NEXTCLOUD: 'Nextcloud',
  BIGBLUEBUTTON: 'BigBlueButton',
  ZOOM: 'Zoom',
  THREEMA: 'Threema',
  THREEMABROADCAST: 'ThreemaBroadcast',
  // MAILBOX: 'Mailbox.org',
};

export type Edges = { edges: [{ properties: { version: string; notBefore: string; notAfter: string } }] };

export type LicensedProductThreema = {
  __typename?: 'Threema';
  uuid?: string;
  apiToken?: string;
  organizationConnection?: Edges;
};

export type LicensedProductNextcloud = {
  __typename?: 'Nextcloud';
  uuid?: string;
  serverUri?: string;
  serverAliase?: string;
  adminUser?: string;
  adminPass?: string;
  organizationConnection?: Edges;
};

export type LicensedProduct =
  | {
      __typename?: 'BigBlueButton';
      uuid?: string;
      organizationConnection?: Edges;
    }
  | LicensedProductNextcloud
  | LicensedProductThreema
  | {
      __typename?: 'ThreemaBroadcast';
      uuid?: string;
      organizationConnection?: Edges;
    }
  | {
      __typename?: 'Zoom';
      uuid?: string;
      organizationConnection?: Edges;
    }
  | {
      __typename?: 'Bildungsplattform';
      uuid?: string;
      organizationConnection?: Edges;
    };

export const SettingsOverviewSubpage = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const perms = usePermissionChecker();
  const [, updateBildungsplattform] = useUpdateBildungsplattformsMutation();

  const context = useMemo(() => {
    return {
      additionalTypenames: ['Group', 'Course'],
    };
  }, []);
  const profileContext = useMemoizedCacheTag('PROFILE');

  const [profilesQueryResult] = useBpAdminProfilesQuery({
    context: profileContext,
    requestPolicy: 'cache-first',
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
  });

  const {
    organizationProducts,
    schoolyears,
    currentSchoolYear,
    setCurrentSchoolYear,
    allowStudentDirectMessages,
    setAllowStudentDirectMessages,
  } = useContext(OrganizationConfigContext);

  const bildungsplattform = organizationProducts?.find((p) => p.__typename === 'Bildungsplattform');
  const schoolyearSelectOptions = useCreateSelectOptions(schoolyears, 'uuid', 'name');

  const handleSelectSchoolyear = async (ev: SelectOptionType) => {
    const selectedSchoolyear = schoolyears?.find((s) => s.uuid === ev.value);
    if (selectedSchoolyear !== undefined) {
      if (bildungsplattform) {
        const resp = await updateBildungsplattform(
          {
            update: {
              currentSchoolyear: selectedSchoolyear.uuid,
            },
            where: { uuid: bildungsplattform.uuid },
          },
          context,
        );
        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          setCurrentSchoolYear(selectedSchoolyear);
          showSuccessToast(t('common.saved'));
        }
      }
    }
  };

  const handleAllowStudentDirectMessages = async (value: boolean) => {
    if (bildungsplattform) {
      const resp = await updateBildungsplattform({
        update: {
          allowStudentDirectMessages: value,
        },
        where: { uuid: bildungsplattform.uuid },
      });
      if (resp.error) {
        showErrorToast(resp.error);
      } else {
        setAllowStudentDirectMessages(value);
        showSuccessToast(t('common.saved'));
      }
    }
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [modalData, setModalData] = useState<LicensedProduct>();

  const onEdit = (licensedProduct: LicensedProduct) => {
    setIsOpen(true);
    setModalData(licensedProduct);
  };

  const tableData = useMemo(() => {
    const possibleLicensedProducts = Object.keys(Products).map((product) => {
      return {
        __typename: Products[product as keyof typeof Products],
      };
    });

    return possibleLicensedProducts.map(
      (possibleLicensedProduct) =>
        (organizationProducts?.find(
          (l) => l.__typename === possibleLicensedProduct.__typename, // show not booked Product without data
        ) as LicensedProduct) ?? possibleLicensedProduct,
    );
  }, [organizationProducts]);

  const memoizedColumns = useMemo((): TableColumns<LicensedProduct>[] => {
    return [
      {
        id: 'module',
        header: t('institution.module'),
        accessorKey: '__typename',
        canExpand: true,
      },
      {
        id: 'begin',
        cell: (cell) => {
          return niceDate(cell.row.original.organizationConnection?.edges[0].properties.notBefore, 'short');
        },
        header: t('institution.bookedDate'),
        size: 100,
        alignment: 'right',
      },
      {
        id: 'validUntil',
        header: t('common.activeUntil'),
        cell: (cell) => {
          return niceDate(cell.row.original.organizationConnection?.edges[0].properties.notAfter, 'short');
        },
        size: 100,
        alignment: 'right',
      },
      {
        id: 'booked',
        accessorKey: 'licenseCount',
        header: t('institution.booked'),
        cell: (cell) => {
          const version = cell.row.original.organizationConnection?.edges[0].properties.version;
          if (version && ['community', 'basic', 'pro'].includes(version)) {
            return t('app.' + cell.row.original.organizationConnection?.edges[0].properties.version);
          } else {
            return version;
          }
        },
        size: 100,
        alignment: 'right',
      },
      {
        id: 'billable',
        accessorKey: 'billable',
        header: t('institution.billable'),
        size: 100,
        alignment: 'right',
      },
      {
        id: 'usage',
        accessorKey: 'usage',
        header: t('institution.usage'),
        size: 100,
        alignment: 'right',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BpSubpage className={styles['settings-overview']}>
      <Grid>
        <GridRow mobileGap='var(--grid-column-gap)' className={styles.top}>
          <GridColumn width={9}>
            <BpCard header={{ headline: t('common.administration') }}>
              <div className={styles['admins-list']}>
                {profilesQueryResult.data?.adminProfiles.map((p) => {
                  return (
                    <div key={p.uuid} className={styles.admin}>
                      <div className={styles.name}>{p.displayName}</div>
                      <div className={styles.roles}>
                        {p.organizationRoles.map((role) => (
                          <div key={role}>{role && adminRolesAsString(role)}</div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </BpCard>
          </GridColumn>
          <GridColumn width={3}>
            <BpCard header={{ headline: t('settings.title') }}>
              <Select
                options={schoolyearSelectOptions}
                onChange={async (e) => {
                  await handleSelectSchoolyear(e as unknown as SelectOptionType);
                }}
                defaultValue={schoolyearSelectOptions.find((opt) => currentSchoolYear?.uuid === (opt.value as string))}
                name={'currentSchoolyear'}
                disabled={!perms?.isOrganizationAdmin(pimAuthClaims.getOrganization())}
                label={t('common.schoolyear')}
              />
              <Checkbox
                name={'allowStudentDirectMessages'}
                checked={allowStudentDirectMessages}
                onChange={async () => {
                  await handleAllowStudentDirectMessages(!allowStudentDirectMessages);
                }}
                disabled={!perms?.isOrganizationAdmin(pimAuthClaims.getOrganization())}
                label={t('institution.allowStudentDirectMessages')}
              />
            </BpCard>
          </GridColumn>
        </GridRow>
        <GridRow>
          <BpCard noPadding header={{ headline: t('institution.actualBookings') }}>
            <Table<LicensedProduct>
              columns={memoizedColumns}
              data={tableData}
              breakpoint='580px'
              customPadding='var(--spacing-6)'
              lastCol={(row) => {
                return (row.original.__typename === 'Bildungsplattform' &&
                  perms?.canUpdateBildungsplattform({ uuid: organizationUuid })) ||
                  (row.original.__typename === 'BigBlueButton' && perms?.canUpdateBBB({ uuid: organizationUuid })) ||
                  (row.original.__typename === 'Zoom' && perms?.canUpdateZoom({ uuid: organizationUuid })) ||
                  (row.original.__typename === 'Threema' && perms?.canUpdateThreema({ uuid: organizationUuid })) ||
                  (row.original.__typename === 'ThreemaBroadcast' &&
                    perms?.canUpdateThreemaBroadcast({ uuid: organizationUuid })) ||
                  (row.original.__typename === 'Nextcloud' && perms?.canUpdateNextcloud({ uuid: organizationUuid })) ? (
                  <Button hierarchy='ghost' icon={<DotsVerticalIcon />} onClick={() => onEdit(row.original)} />
                ) : (
                  <></>
                );
              }}
            />
          </BpCard>
        </GridRow>
      </Grid>

      <BookingsModal isOpen={isOpen} closeModal={setIsOpen} data={modalData} />
    </BpSubpage>
  );
};
