import { useTranslation } from 'react-i18next';
import { useBpDeleteAssignmentsMutation } from '../../client/bp-graphql-client-defs';
import { Assignment, SubmissionStatus } from '@bp/bp-graphql-types';
import { Button, Chip, DotsHorizontalIcon, Dropdown, DropdownMenu } from '@bp/ui-components';
import styles from './AssignmentPreview.module.scss';
import { showErrorToast } from '../../utils/showErrorToast';
import { useWindowDimensions } from '../../utils/dimensions';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { FC } from 'react';
import classNames from 'classnames';
import { BpLink } from 'components/BpLink/BpLink';
import { useConfirm } from '../../hooks/useConfirm';
import { useMatrixAvailable } from 'hooks/matrix/useMatrixAvailable';
import { useGetMatrixRooms } from 'hooks/matrix/useGetMatrixRooms';
import { useRefreshOnEvent } from 'hooks/matrix/useRefreshOnEvent';
import { useDays } from '../../hooks/useDays';
import { NotificationReasons } from '../../utils/matrixClient';
import { useParams } from 'react-router-dom';
import { backendApi } from '../../utils/backendApi';

type AssignmentPreviewTeacherProps = {
  assignment: Assignment;
  onEditClick: () => void;
  onClick: (uuid: string) => void;
  className?: string | undefined;
};

export const AssignmentPreviewTeacher: FC<AssignmentPreviewTeacherProps> = ({
  assignment,
  onEditClick,
  onClick,
  className,
}) => {
  const { t } = useTranslation();
  useMatrixAvailable();
  useRefreshOnEvent();
  const rooms = useGetMatrixRooms();
  const { courseUuid } = useParams();
  const { daysLeft } = useDays();

  const context = useMemoizedCacheTag('ASSIGNMENT');

  const [, deleteAssignment] = useBpDeleteAssignmentsMutation();
  const handleDelete = async (assignment: Assignment) => {
    const { error } = await deleteAssignment(
      {
        where: { uuid: assignment.uuid },
      },
      context,
    );

    error && showErrorToast(error);
    if (!error) {
      showSuccessToast(t('delete.deleted', { type: t('assignments.task') }));

      void backendApi.notify({
        type: NotificationReasons.AssignmentDeleted,
        groupUuid: courseUuid,
        subjectName: assignment.title,
      });
    }
  };

  const { confirm: confirmDelete, ConfirmationDialog } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('assignments.titleSingular'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  const daysDifference = daysLeft(assignment.dueDate, true);
  const newSubmissions = assignment.submissions.filter((s) => s.status === SubmissionStatus.New).length;
  const openSubmissions = assignment.submissions.filter(
    (s) => s.status !== SubmissionStatus.New && s.status !== SubmissionStatus.Done,
  ).length;

  const chipText: string =
    useWindowDimensions().width >= 1250
      ? `${newSubmissions} ${t('submissions.newSubmissions')}`
      : newSubmissions.toString();

  const classes = classNames(styles['assignment-preview'], styles['assignment-preview-teacher'], className);

  let newMessages = false;
  assignment.submissions.forEach((submission) => {
    const room = rooms?.find((room) => {
      return room.name.includes(submission.uuid);
    });
    if (room && !newMessages) {
      newMessages = room.getRoomUnreadNotificationCount() > 0;
    }
  });

  return (
    <div className={classes}>
      <div className={styles.top}>
        <div className={styles.text}>
          {assignment?.dueDate && (
            <div className={styles['due-days']}>
              {daysDifference < 0
                ? t('assignments.overdue')
                : t('dates.daysToGo', {
                    count: daysDifference,
                  })}
            </div>
          )}
          <BpLink value={assignment?.title} onNavigate={() => onClick(assignment.uuid)} isEditorOfCourse={true} />
        </div>
        <Dropdown
          noPadding
          trigger={
            <Button
              hierarchy='ghost'
              icon={<DotsHorizontalIcon className='svg-icon small' />}
              className={styles.actions}
            />
          }
        >
          <DropdownMenu
            data={[
              {
                onClick: onEditClick,
                label: t('common.edit'),
              },

              {
                type: 'ruler',
              },

              {
                color: 'error',
                label: t('delete.delete'),
                onClick: async () => {
                  const res = await confirmDelete();
                  if (res) {
                    handleDelete(assignment);
                  }
                },
              },
            ]}
          />
        </Dropdown>
      </div>

      <div className={styles.bottom}>
        <div className={styles.info}>
          <Chip value={chipText} />
          {newMessages && <div className={'bp__dot'} />}
        </div>
        <div className={styles.open}>
          <div className={styles.text}>{t('common.open')}</div>
          <div className={styles.count}>{openSubmissions}</div>
        </div>
      </div>

      <ConfirmationDialog />
    </div>
  );
};
